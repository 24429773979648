import React, { useCallback, useEffect, useState } from 'react';
import { defaultTo, find, findIndex, groupBy, isEmpty, uniqBy } from 'lodash';
import { Box, Button, FormHelperText, Stack, Typography } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { fDateISO, fDateLong, fNowUtcToTz, fTime } from '../../../utils/formatTime';
import { DEAFULT_TIMEZONE } from '../../../utils/constants';
import { Loader } from '../../core/Loader';

const SlotButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 1.25),
  minHeight: '40px',
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

const DatePickerRootStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`,
  padding: theme.spacing(2),
  color: 'unset'
}));

const StaticDatePickerStyle = styled(StaticDatePicker)(({ theme }) => ({
  backgroundColor: 'transparent !important',
  minWidth: '240px !important',
  '.MuiDateCalendar-root': {
    width: '100%',
    maxHeight: `374px`,
    height: 'auto'
  },
  '.MuiDayCalendar-header': {
    marginTop: theme.spacing(3),
    justifyContent: 'space-evenly !important'
  },
  '.MuiPickersSlideTransition-root': {
    '& div[role=row]': {
      justifyContent: 'space-evenly !important'
    }
  },
  '.MuiPickersCalendarHeader-label': {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 600,
    lineHeight: '24px',
    padding: theme.spacing(2, 0)
  },
  '.MuiPickersDay-root': {
    color: theme.palette.primary.main,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: alpha(theme.palette.primary.main, 0.24)
    },
    '&.Mui-disabled:not(.Mui-selected)': {
      color: `${theme.palette.text.disabled} !important`
    },
    '&.Mui-selected': {
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: theme.palette.primary.main,
      '&:focus': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
      },
      '&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: alpha(theme.palette.primary.main, 0.24)
      }
    }
  }
}));

const BookingAvailabilityHeader = styled((props) => <Typography variant="overline" {...props} />)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: 'uppercase'
}));

const BookingAvailabilityText = styled((props) => <Typography variant="body2" {...props} />)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightMedium
}));

const BookingSlotList = ({ data, handleClick }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {data.map((slot) => (
        <Box key={slot.timestamp}>
          <SlotButton variant="contained" color="primary" onClick={() => handleClick(slot)}>
            {fTime(slot.from)}
          </SlotButton>
        </Box>
      ))}
    </Box>
  );
};

const BookingAvailability = ({ data, selected, handleUpdateSlot }) => {
  const selectedDateIso = fDateISO(selected);
  const found = find(defaultTo(data, []), { date: selectedDateIso });
  const slots = defaultTo(found?.slots, []);
  const grouped = groupBy(slots, (o) => {
    const dttm = new Date(o.from);
    if (dttm.getHours() < 12) {
      return 'morning';
    }
    if (dttm.getHours() >= 12 && dttm.getHours() < 18) {
      return 'afternoon';
    }
    return 'evening';
  });

  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <BookingAvailabilityHeader>Morning</BookingAvailabilityHeader>
        {isEmpty(grouped.morning) ? (
          <BookingAvailabilityText>No Availability</BookingAvailabilityText>
        ) : (
          <BookingSlotList data={grouped.morning} handleClick={handleUpdateSlot} />
        )}
      </Stack>
      <Stack spacing={1}>
        <BookingAvailabilityHeader>Afternoon</BookingAvailabilityHeader>
        {isEmpty(grouped.afternoon) ? (
          <BookingAvailabilityText>No Availability</BookingAvailabilityText>
        ) : (
          <BookingSlotList data={grouped.afternoon} handleClick={handleUpdateSlot} />
        )}
      </Stack>
      <Stack spacing={1}>
        <BookingAvailabilityHeader>Evening</BookingAvailabilityHeader>
        {isEmpty(grouped.evening) ? (
          <BookingAvailabilityText>No Availability</BookingAvailabilityText>
        ) : (
          <BookingSlotList data={grouped.evening} handleClick={handleUpdateSlot} />
        )}
      </Stack>
    </Stack>
  );
};

const INITIAL_AVAILABILITY_STATE = {
  loading: false,
  data: []
};

const SEARCH_NUM_OF_MONTHS = 3;
const MAX_SEARCH_NUM_OF_MONTHS = 36;

export const BookingAvailabilityPage = ({ timezone: timezoneRoot, handleGetBookingAvailability, handleUpdateSlot }) => {
  const timezone = defaultTo(timezoneRoot, DEAFULT_TIMEZONE)?.id;
  const today = fNowUtcToTz(timezone);
  const [date, setDate] = useState(today);
  const [availability, setAvailability] = useState(INITIAL_AVAILABILITY_STATE);

  const getMaxDate = useCallback((date) => {
    const endDate = new Date(date);
    endDate.setMonth(date.getMonth() + MAX_SEARCH_NUM_OF_MONTHS);
    endDate.setDate(date.getDate() - 1);
    return endDate;
  }, []);

  // ISO format e.g. 2025-01-01
  const checkMonthExists = useCallback(
    (value) => findIndex(defaultTo(availability.data, []), { date: value }) >= 0,
    [availability.data]
  );

  const mapToAvailability = useCallback((prev, data) => {
    const update = [...prev, ...defaultTo(data, [])];
    return uniqBy(update, 'date');
  }, []);

  const getFirstDateOfMonth = useCallback((value) => {
    const date = new Date(value);
    date.setDate(1);
    return date;
  }, []);

  const getDateRangeRequest = useCallback((value) => {
    const startDate = new Date(value);
    const endDate = new Date(startDate);
    // Number of months to look forward
    endDate.setMonth(startDate.getMonth() + SEARCH_NUM_OF_MONTHS);
    // Subtract 1 day to avoid missing a months data as this is fired on the first of the month
    endDate.setDate(startDate.getDate() - 1);
    return [fDateISO(startDate), fDateISO(endDate)];
  }, []);

  const handleGetAvailability = useCallback(
    async (from, to) => {
      try {
        setAvailability((prev) => ({ ...prev, loading: true }));
        const response = await handleGetBookingAvailability(from, to);
        setAvailability((prev) => ({ ...prev, data: mapToAvailability(prev.data, response), loading: false }));
      } catch (e) {
        setAvailability((prev) => ({ ...prev, loading: false }));
      }
    },
    [handleGetBookingAvailability, mapToAvailability, setAvailability]
  );

  const handleMonthChange = useCallback(
    (value) => {
      const date = fDateISO(value);
      if (!checkMonthExists(date)) {
        const [from, to] = getDateRangeRequest(date);
        handleGetAvailability(from, to);
      }
    },
    [handleGetAvailability, getDateRangeRequest, checkMonthExists]
  );

  const handleSearchInitialAvailability = useCallback(() => {
    if (today) {
      const firstDayOfMonth = getFirstDateOfMonth(today);
      const [from, to] = getDateRangeRequest(firstDayOfMonth);
      handleGetAvailability(from, to);
    }
  }, [today, getFirstDateOfMonth, getDateRangeRequest, handleGetAvailability]);

  const handleChangeDate = useCallback((value) => setDate(value), [setDate]);

  const handleDisableDate = useCallback(
    (value) => {
      const formatted = fDateISO(value);
      return !find(availability.data, { date: formatted, available: true });
    },
    [availability.data]
  );

  useEffect(() => {
    if (!availability.loading && availability.data === INITIAL_AVAILABILITY_STATE.data) {
      handleSearchInitialAvailability();
    }
  }, [availability.data, availability.loading, handleSearchInitialAvailability]);

  return (
    <Box>
      <Stack spacing={6}>
        <DatePickerRootStyle>
          <StaticDatePickerStyle
            displayStaticWrapperAs="desktop"
            disablePast
            disableHighlightToday
            views={['day']}
            value={date}
            loading={availability.loading}
            renderLoading={() => <Loader />}
            shouldDisableDate={handleDisableDate}
            minDate={today}
            maxDate={getMaxDate(today)}
            onMonthChange={handleMonthChange}
            onChange={handleChangeDate}
            slots={{
              day: (props) => {
                const { disabled } = props;
                return <PickersDay {...props} sx={{ ...(disabled && { textDecoration: 'line-through' }) }} />;
              }
            }}
          />
          <FormHelperText>{`All times are shown in ${timezone} timezone`}</FormHelperText>
        </DatePickerRootStyle>
        <Stack spacing={2}>
          {date && <Typography variant="h5">{fDateLong(date)}</Typography>}
          <Box>
            {availability.loading ? (
              <Box sx={{ height: '100px' }}>
                <Loader />
              </Box>
            ) : (
              <>
                <BookingAvailability data={availability.data} selected={date} handleUpdateSlot={handleUpdateSlot} />
              </>
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
