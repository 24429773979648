import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { styled } from '@material-ui/core/styles';
import { Stack, Box, Button, Grid, Container } from '@material-ui/core';
import { isEmpty, isEqual, min } from 'lodash';
import { getComponentPadding, getContainerWidth } from '../../utils/getPadding';
import { isInvalid } from '../../utils/nullable';
import { getDesktopItemsPerRow } from '../../utils/getItemsPerRow';
import { COLLECTIONS_PATH, SYSTEM_PAGE } from '../../utils/pageTypes';
import useMobile from '../../hooks/useMobile';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { varFadeInUp, MotionInView } from '../core/animate';
import {
  MOBILE_STACK_SPACING,
  DESKTOP_STACK_SPACING,
  COLLECTION_LIST_PLACEHOLDER_HEIGHT,
  PRODUCTS_TABLE_VIEW_TYPE,
  DEFAULT_DESKTOP_GRID_SIZE,
  DEFAULT_COLLECTIONS_MISSING_TITLE,
  DEFAULT_COLLECTIONS_MISSING_DESCRIPTION
} from '../../utils/constants';
import { ComponentHeaderV2 } from './ComponentHeader';
import { CompactCollectionListItem } from '../collections/CompactCollectionListItem';
import { CollectionCardListItem } from '../collections/CollectionCardListItem';
import EmptyContent from '../core/EmptyContent';

const PlaceholderView = styled(Box)(() => ({
  height: COLLECTION_LIST_PLACEHOLDER_HEIGHT
}));

function HomeCollectionList({ component: config, handleGetCollections, handleNavigationClick = () => {} }) {
  const isMountedRef = useIsMountedRef();
  const { resources = [], numberOfCollections = 6 } = config;
  const [collections, setCollections] = useState(null);
  // Reference to avoid reloading (via editor) when resources are unchanged
  const loadedResourcesRef = useRef({
    resources: null,
    size: numberOfCollections
  });
  const isMobile = useMobile();

  const getCollections = useCallback(() => {
    const shouldGetCollections =
      isMountedRef.current &&
      Boolean(
        !isEqual(loadedResourcesRef.current.resources, resources) ||
          !isEqual(loadedResourcesRef.current.size, numberOfCollections)
      );

    if (shouldGetCollections) {
      loadedResourcesRef.current = {
        resources,
        size: numberOfCollections
      };
      handleGetCollections(resources, numberOfCollections).then((_collections) => {
        setCollections(_collections);
      });
    }
  }, [isMountedRef, resources, handleGetCollections, numberOfCollections]);

  useEffect(() => {
    getCollections();
  }, [getCollections]);

  const {
    title,
    caption,
    titleColour,
    captionColour,
    desktopCollectionRows = 2,
    mobileCollectionRows = 1,
    buttonText = '',
    container,
    productsViewType,
    compactCollectionView,
    desktopSpacing,
    mobileSpacing
  } = config;
  const isWideView = productsViewType === PRODUCTS_TABLE_VIEW_TYPE[1];
  const showHeader = !isEmpty(caption) || !isEmpty(title);
  const containerWidth = getContainerWidth(container, isMobile);

  const getMobileRows = useCallback(
    () => 12 / min([mobileCollectionRows, numberOfCollections]),
    [mobileCollectionRows, numberOfCollections]
  );
  const getDesktopRows = useCallback(
    () =>
      desktopCollectionRows
        ? getDesktopItemsPerRow(desktopCollectionRows, numberOfCollections)
        : DEFAULT_DESKTOP_GRID_SIZE,
    [desktopCollectionRows, numberOfCollections]
  );

  const CollectionsViewMemo = useMemo(() => {
    const messaging = config?.global?.messages?.collections;
    const gridSizing = {
      xs: isMobile ? getMobileRows() : getDesktopRows()
    };
    return collections && !isEmpty(collections) ? (
      <MotionInView variants={varFadeInUp}>
        <Box>
          <Grid container direction="row" spacing={isWideView ? 0 : 3}>
            {collections.slice(0, numberOfCollections).map((_collection) => (
              <Grid key={_collection.id} item {...gridSizing}>
                {compactCollectionView ? (
                  <CompactCollectionListItem
                    config={config}
                    isWideView={isWideView}
                    collection={_collection}
                    handleNavigationClick={handleNavigationClick}
                  />
                ) : (
                  <CollectionCardListItem
                    config={config}
                    isWideView={isWideView}
                    collection={_collection}
                    handleNavigationClick={handleNavigationClick}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </MotionInView>
    ) : (
      <PlaceholderView>
        <EmptyContent
          title={isInvalid(messaging?.title) ? DEFAULT_COLLECTIONS_MISSING_TITLE : messaging?.title}
          description={
            isInvalid(messaging?.description) ? DEFAULT_COLLECTIONS_MISSING_DESCRIPTION : messaging?.description
          }
          sx={{ height: '100%' }}
        />
      </PlaceholderView>
    );
  }, [
    isMobile,
    config,
    collections,
    isWideView,
    numberOfCollections,
    compactCollectionView,
    handleNavigationClick,
    getMobileRows,
    getDesktopRows
  ]);

  return (
    <Box>
      <Container
        maxWidth={containerWidth}
        sx={{
          position: 'relative',
          height: '100%',
          '&.MuiContainer-root': {
            ...(!containerWidth && { paddingLeft: 0, paddingRight: 0 })
          }
        }}
      >
        <Box sx={{ padding: (theme) => getComponentPadding(desktopSpacing, mobileSpacing, isMobile, theme) }}>
          <Stack spacing={isMobile ? MOBILE_STACK_SPACING : DESKTOP_STACK_SPACING}>
            {showHeader && (
              <ComponentHeaderV2
                caption={caption}
                title={title}
                titleColour={titleColour}
                captionColour={captionColour}
              />
            )}

            {CollectionsViewMemo}

            {!isEmpty(buttonText) && (
              <Box sx={{ width: '100%', textAlign: 'center' }}>
                <Button variant="contained" onClick={() => handleNavigationClick(SYSTEM_PAGE, COLLECTIONS_PATH)}>
                  {buttonText}
                </Button>
              </Box>
            )}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default HomeCollectionList;
