import React from 'react';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: theme.spacing(0),
    width: '100%',
    borderRadius: 0,
    border: 'none',
    '&::before': {
      top: 1
    }
  }
}));

const AccordionSummaryStyle = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(0)
}));

const AccordionDetailsStyle = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1, 0)
}));

export const BookingDropdownActionView = ({ colour, variantTitle, defaultExpanded, title, children }) => (
  <Box>
    <AccordionStyle disableGutters elevation={0} defaultExpanded={defaultExpanded}>
      <AccordionSummaryStyle expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} color="primary" />}>
        <Typography variant={variantTitle || 'h6'} {...(colour && { color: colour })}>
          {title}
        </Typography>
      </AccordionSummaryStyle>
      <AccordionDetailsStyle>{children}</AccordionDetailsStyle>
    </AccordionStyle>
  </Box>
);
