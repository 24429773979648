import React from 'react';
import { Divider, Stack } from '@material-ui/core';
import { defaultTo, isEmpty } from 'lodash';
import {
  DEFAULT_BOOKING_CANCELLLATION_POLICY_MESSAGE,
  DEFAULT_BOOKING_CANCELLLATION_POLICY_TITLE,
  DEFAULT_BOOKING_NOTE_TITLE
} from '../../../utils/constants';
import MTextField from '../../core/@material-extend/MTextField';
import { PhoneNumberInput } from '../../core/PhoneNumberInput';
import Markdown from '../../core/Markdown';
import { BookingDropdownActionView } from '../components/BookingDropdownActionView';
import { BookingQuestions } from '../BookingQuestions';

export const BookingCompletePage = ({ isMobile, shape, config, form, children }) => {
  const enableBookingNote = config?.general?.enableBookingNote;
  const bookingNoteTitle = config?.general?.bookingNoteTitle;
  const showCancelPolicy = config?.general?.showCancelPolicy;
  const cancelPolicyTitle = config?.general?.cancelPolicyTitle;
  const cancelPolicyMessage = config?.general?.cancelPolicyMessage;
  const questions = defaultTo(config?.general?.questions, []);
  const showBookingQuestions = config?.general?.enableQuestions && !isEmpty(questions);
  const expandQuestionsByDefault = config?.general?.expandQuestionsByDefault;
  const bookingQuestionsTitle = config?.general?.questionsTitle;

  const { getFieldProps, touched, errors } = form;

  return (
    <Stack spacing={2} divider={<Divider />}>
      <Stack spacing={3} divider={<Divider />} sx={{ mb: 2 }}>
        <PhoneNumberInput
          form={form}
          fullWidth
          shape={shape}
          key="number"
          label="Number"
          {...getFieldProps('number')}
          error={Boolean(touched.number && errors.number)}
          helperText={touched.number && errors.number}
        />
        <Stack spacing={2}>
          <Stack spacing={1} direction={isMobile ? 'column' : 'row'}>
            <MTextField
              fullWidth
              shape={shape}
              key="firstname"
              label="First Name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <MTextField
              fullWidth
              shape={shape}
              key="lastname"
              label="Last Name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
          <MTextField
            fullWidth
            shape={shape}
            key="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>
      </Stack>

      {children}

      {showBookingQuestions && (
        <BookingQuestions
          shape={shape}
          title={bookingQuestionsTitle}
          form={form}
          shouldExpand={expandQuestionsByDefault}
        />
      )}

      {enableBookingNote && (
        <BookingDropdownActionView
          colour="primary"
          title={bookingNoteTitle || DEFAULT_BOOKING_NOTE_TITLE}
          defaultExpanded={false}
        >
          <MTextField
            shape={shape}
            fullWidth
            multiline
            minRows={3}
            maxRows={15}
            autoComplete="off"
            {...getFieldProps('notes')}
          />
        </BookingDropdownActionView>
      )}

      {showCancelPolicy && (
        <BookingDropdownActionView
          title={cancelPolicyTitle || DEFAULT_BOOKING_CANCELLLATION_POLICY_TITLE}
          defaultExpanded
        >
          <Markdown children={cancelPolicyMessage || DEFAULT_BOOKING_CANCELLLATION_POLICY_MESSAGE} />
        </BookingDropdownActionView>
      )}
    </Stack>
  );
};
