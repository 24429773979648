import React, { useCallback, useMemo } from 'react';
import { defaultTo, isEmpty } from 'lodash';
import { styled } from '@material-ui/core/styles';
import { Card, CardMedia, CardActionArea, Box, Typography, Stack } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import missingImageDefault from '../../static/placeholder/product_image.jpg';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import { varFadeIn, MotionInView } from '../core/animate';
import { DEFAULT_IMAGE_HEIGHT } from '../../utils/constants';
import { getImageFit, getImagePosition, getTextAlignPosition } from '../../utils/getPositions';
import { isInvalidText } from '../../utils/nullable';

export const AspectRatio = AspectRatioPrimitive;

const Root = styled(Box)(() => ({
  position: 'relative',
  cursor: 'pointer',
  padding: 0
}));

const CardStyle = styled(Card)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  borderRadius: 0
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm
}));

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm
}));

const TextContent = ({ position, title, subtitle, caption, titleColour, subTitleColour, captionColour }) => {
  const alignPosition = getTextAlignPosition(position);
  return (
    <Stack spacing={0} sx={{ px: 1 }}>
      {!isInvalidText(caption) && (
        <Typography
          variant="overline"
          textAlign={alignPosition}
          sx={{
            textTransform: 'uppercase',
            color: (theme) => captionColour || theme.palette.text.secondary
          }}
        >
          {caption}
        </Typography>
      )}
      {!isInvalidText(title) && (
        <Typography
          variant="h6"
          textAlign={alignPosition}
          sx={{ color: (theme) => titleColour || theme.palette.primary.main }}
        >
          {title}
        </Typography>
      )}
      {!isInvalidText(subtitle) && (
        <Typography
          variant="body2"
          textAlign={alignPosition}
          sx={{ color: (theme) => subTitleColour || theme.palette.text.primary }}
        >
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
};

export const GalleryImageItem = ({ isMobile, showImagePreview, item, handleOpenPreview, handleNavigationClick }) => {
  const {
    enableNavigation,
    title,
    subtitle,
    caption,
    titleColour,
    subTitleColour,
    captionColour,
    textPosition,
    navigation,
    desktopImage,
    imagePosition,
    mobileFixedImageHeight,
    mobileFixedImageHeightValue,
    mobileFixedImageHeightPosition,
    fixedImageHeight,
    fixedImageHeightValue,
    fixedImageHeightPosition
  } = item;
  const fixedHeight = isMobile ? mobileFixedImageHeight : fixedImageHeight;
  const imageHeight = isMobile ? mobileFixedImageHeightValue : fixedImageHeightValue;
  const imageHeightPosition = isMobile ? mobileFixedImageHeightPosition : fixedImageHeightPosition;
  const isContentEmpty = Boolean(isEmpty(title) && isEmpty(subtitle) && isEmpty(caption));

  const handleCardClick = useCallback(() => {
    if (enableNavigation) {
      handleNavigationClick(navigation?.type, navigation?.path);
    }
    if (!enableNavigation) {
      handleOpenPreview(desktopImage);
    }
  }, [enableNavigation, navigation, desktopImage, handleNavigationClick, handleOpenPreview]);

  const ImageContentMemo = useMemo(
    () =>
      fixedHeight ? (
        <StyledCardMedia
          component={MLazyLoadImage}
          source={desktopImage || missingImageDefault}
          imgSx={{
            height: defaultTo(imageHeight, DEFAULT_IMAGE_HEIGHT),
            objectPosition: getImagePosition(imageHeightPosition),
            objectFit: getImageFit(imagePosition)
          }}
          sx={{
            height: defaultTo(imageHeight, DEFAULT_IMAGE_HEIGHT),
            width: '100%',
            borderRadius: (theme) => theme.shape.borderRadiusSm
          }}
        />
      ) : (
        <AspectRatio.Root ratio={1 / 1}>
          <StyledCardMedia
            component={MLazyLoadImage}
            source={desktopImage || missingImageDefault}
            imgSx={{
              height: '100%',
              width: '100%',
              objectPosition: getImagePosition(imageHeightPosition),
              objectFit: getImageFit(imagePosition)
            }}
            sx={{ height: '100%', width: '100%', borderRadius: (theme) => theme.shape.borderRadiusSm }}
          />
        </AspectRatio.Root>
      ),
    [desktopImage, imagePosition, fixedHeight, imageHeight, imageHeightPosition]
  );

  return (
    <Root>
      <MotionInView variants={varFadeIn}>
        <CardStyle
          {...((showImagePreview || enableNavigation) && { onClick: handleCardClick })}
          sx={{ ...(!showImagePreview && !enableNavigation && { cursor: 'default' }) }}
        >
          <Stack spacing={2}>
            {showImagePreview ? <StyledCardActionArea>{ImageContentMemo}</StyledCardActionArea> : ImageContentMemo}
            {!isContentEmpty && (
              <TextContent
                position={textPosition}
                title={title}
                caption={caption}
                subtitle={subtitle}
                titleColour={titleColour}
                captionColour={captionColour}
                subTitleColour={subTitleColour}
              />
            )}
          </Stack>
        </CardStyle>
      </MotionInView>
    </Root>
  );
};
