import React from 'react';
import { compact, defaultTo, isEmpty, reduce, sum } from 'lodash';
import { Box, Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineDot,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineItem
} from '@material-ui/lab';
import { styled } from '@material-ui/core/styles';
import { fCurrency } from '../../../utils/formatNumber';
import { BookingTextBlock } from '../BookingTextBlock';
import { isZero } from '../../../utils/money';
import { SkeletonBookingSummary } from '../../core/SkeletonBookingSummary';
import { EmptyBookingServicesItem } from './EmptyBookingServicesItem';
import { BookingItemRootStyle } from './BookingItemRootStyle';

const TimelineDotStyle = styled(TimelineDot)(({ theme }) => ({
  borderColor: theme.palette.divider
}));

const TimelineItemStyle = styled(TimelineItem)(() => ({
  minHeight: 'unset',
  '&.MuiTimelineItem-root': {
    '&::before': {
      flex: 0,
      padding: 0
    }
  }
}));

export const BookingServicesTimeline = ({ loading, currency, services }) => {
  if (loading) {
    return (
      <Box sx={{ p: 2 }}>
        <SkeletonBookingSummary />
      </Box>
    );
  }
  if (isEmpty(services)) {
    return <EmptyBookingServicesItem title="No services added" />;
  }
  const noServicesWithDuration = isEmpty(compact(services.map((o) => o.duration)));
  const totalDuration = reduce(
    defaultTo(services, []).map((o) => defaultTo(o.duration, 0)),
    (a, n) => sum([a, n])
  );
  const showConnector = services.length > 1;
  return (
    <Box>
      <Timeline position="right" sx={{ p: 2 }}>
        <Typography variant="overline" color="text.secondary" sx={{ mb: 1 }}>
          {`${services.length} SERVICES`}
        </Typography>
        {services.map((service, index) => {
          const isLastItem = services.length - 1 === index;
          const { symbol } = currency;
          const { id, name, price, duration: durationInMins } = service;
          const supportText = [];
          if (price) {
            const formattedPrice = fCurrency(price?.amount, symbol);
            supportText.push(formattedPrice);
          }
          if (durationInMins) {
            supportText.push(`${durationInMins} min`);
          }
          return (
            <TimelineItemStyle key={id}>
              {showConnector && (
                <TimelineSeparator>
                  <TimelineDotStyle variant="outlined" />
                  {!isLastItem && <TimelineConnector />}
                </TimelineSeparator>
              )}

              <TimelineContent sx={{ py: 0, ...(!showConnector && { px: 0 }), ...(!isLastItem && { mb: 3 }) }}>
                <BookingItemRootStyle sx={{ p: 0 }}>
                  <BookingTextBlock title={name} supportText={supportText} sx={{ px: 0, pt: 0 }} spacing={0} />
                </BookingItemRootStyle>
              </TimelineContent>
            </TimelineItemStyle>
          );
        })}
        {!!totalDuration && !isZero(totalDuration) && !noServicesWithDuration && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mt: 2 }}
          >{`Total duration: ${totalDuration} min`}</Typography>
        )}
      </Timeline>
    </Box>
  );
};
