import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Stack, Divider, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { getTextAlignPosition } from '../../../utils/getPositions';
import { PRODUCT_PAGE } from '../../../utils/pageTypes';
import { fCurrency } from '../../../utils/formatNumber';
import { getStatusLabel } from '../../../utils/getStatusLabel';
import { ColourChip } from '../ColourChip';

const HeadingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}));

export const ProductInformation = ({
  currency,
  isWidget,
  name,
  resource,
  position,
  status,
  saleBgColour,
  tagBgColour,
  outOfStockBgColour,
  saleTextColour,
  tagTextColour,
  outOfStockTextColour,
  showProductTag,
  showSku,
  selectedSku,
  showProductColours,
  availableColours,
  selectedPrice,
  selectedPriceSale,
  handleNavigationClick
}) => {
  const isShowingSku = Boolean(showSku && selectedSku);
  const isShowingColours = Boolean(showProductColours && !isEmpty(availableColours));
  return (
    <Stack spacing={1} direction="column" sx={{ ...(position && { alignItems: getTextAlignPosition(position) }) }}>
      {showProductTag && status && (
        <Box sx={{ height: '30px' }}>
          {getStatusLabel(status, {
            saleBgColour,
            tagBgColour,
            outOfStockBgColour,
            saleTextColour,
            tagTextColour,
            outOfStockTextColour
          })}
        </Box>
      )}

      {!isEmpty(name) && (
        <HeadingText
          variant="h5"
          sx={{
            ...(isWidget && { cursor: 'pointer' }),
            ...(position && { textAlign: getTextAlignPosition(position) })
          }}
          {...(isWidget && {
            onClick: () => {
              if (resource) {
                handleNavigationClick(PRODUCT_PAGE, resource);
              }
            }
          })}
        >
          {name}
        </HeadingText>
      )}
      {isShowingSku && (
        <Box sx={{ ...(position && { textAlign: getTextAlignPosition(position) }) }}>
          <HeadingText variant="body1" sx={{ mb: (theme) => theme.spacing(0.5), fontWeight: 'bold' }}>
            Stock Keeping Unit
          </HeadingText>
          <Typography color="text.secondary">{selectedSku}</Typography>
        </Box>
      )}
      {isShowingColours && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: getTextAlignPosition(position) }}>
          {availableColours.map((colour, index) => (
            <ColourChip
              key={colour}
              sx={{ backgroundColor: colour, ...(Boolean(index === availableColours.length - 1) && { mr: 0 }) }}
            />
          ))}
        </Box>
      )}

      <Typography color="primary" variant="h5" sx={{ fontWeight: 'bold' }}>
        {fCurrency(selectedPrice, currency)}
        {selectedPriceSale && (
          <Box component="span" sx={{ color: 'text.disabled', textDecoration: 'line-through', ml: 2 }}>
            {fCurrency(selectedPriceSale, currency)}
          </Box>
        )}
      </Typography>
      <Divider />
    </Stack>
  );
};
