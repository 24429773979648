import React, { useMemo } from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Container, Grid } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import { defaultTo } from 'lodash';
import { getComponentPadding, getComponentPaddingOrZero, getContainerWidth } from '../../utils/getPadding';
import { getHorizontalPosition, getImageFit, getVerticalPosition } from '../../utils/getPositions';
import useMobile from '../../hooks/useMobile';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import { varFadeIn, MotionInView } from '../core/animate';
import placeholderImage from '../../static/placeholder/Image_and_text.jpg';
import LayoutOrientation from '../core/LayoutOrientation';
import { getSelectedImage } from '../../utils/getSelectedImage';
import { MarkdownTextBlock } from '../core/TextBlock';
import { createOverlayWithSpread } from '../../utils/createOverlay';
import { gridSizing } from '../../utils/grid';
import { isInvalidText } from '../../utils/nullable';

export const AspectRatio = AspectRatioPrimitive;

const HEIGHT_CONSTRAINT_STYLE = {
  minHeight: '400px'
};

const RootImageStyle = styled(Box)(() => ({
  position: 'relative',
  height: '100%'
}));

const RootContentStyle = styled(Box)({
  position: 'relative',
  height: '100%'
});

const ImageStyle = styled(MLazyLoadImage)(() => ({
  width: '100%',
  height: '100%',
  ...HEIGHT_CONSTRAINT_STYLE
}));

const BackgroundOverlay = styled(Box)(() => ({
  top: 0,
  zIndex: 2,
  width: '100%',
  height: '100%',
  position: 'absolute'
}));

function VerticalImageAndText({ component, handleNavigationClick = () => {} }) {
  const isMobile = useMobile();

  const VerticalImageAndTextMemo = useMemo(() => {
    const {
      title,
      contentPosition: contentPositionDesktop,
      mobileContentPosition: contentPositionMobile,
      showOverlay,
      overlayDirection,
      overlaySpread,
      overlayColourFirst: overlayColourFirstRoot,
      overlayColourSecond: overlayColourSecondRoot,
      desktopImage,
      mobileImage,
      navigation,
      backgroundColour,
      switchDesktopOrientation,
      imagePosition,
      container,
      desktopSpacing,
      mobileSpacing,
      desktopContentSpacing,
      mobileContentSpacing,
      desktopImageSpacing,
      mobileImageSpacing
    } = component;
    const contentPosition = isMobile
      ? defaultTo(contentPositionMobile, contentPositionDesktop)
      : contentPositionDesktop;
    const img = getSelectedImage(isMobile, desktopImage, mobileImage);
    const containerWidth = getContainerWidth(container, isMobile);

    return (
      <Box sx={{ ...(backgroundColour && { backgroundColor: backgroundColour }) }}>
        <Container
          maxWidth={containerWidth}
          sx={{
            position: 'relative',
            height: '100%',
            '&.MuiContainer-root': {
              ...(!containerWidth && { paddingLeft: 0, paddingRight: 0 })
            }
          }}
        >
          <Box
            sx={{
              padding: (theme) => getComponentPaddingOrZero(desktopSpacing, mobileSpacing, isMobile, theme)
            }}
          >
            <Grid container direction={switchDesktopOrientation ? 'row-reverse' : 'row'}>
              <Grid item {...gridSizing(isMobile)}>
                <RootContentStyle
                  sx={{
                    padding: (theme) =>
                      getComponentPadding(desktopContentSpacing, mobileContentSpacing, isMobile, theme)
                  }}
                >
                  <LayoutOrientation
                    verticalPosition={getVerticalPosition(contentPosition)}
                    horizontalPosition={getHorizontalPosition(contentPosition)}
                  >
                    <MarkdownTextBlock
                      {...component}
                      position={getHorizontalPosition(contentPosition)}
                      onButtonClick={() => handleNavigationClick(navigation?.type, navigation?.path)}
                    />
                  </LayoutOrientation>
                </RootContentStyle>
              </Grid>
              <Grid item {...gridSizing(isMobile)}>
                <RootImageStyle
                  sx={{
                    padding: (theme) =>
                      getComponentPaddingOrZero(desktopImageSpacing, mobileImageSpacing, isMobile, theme)
                  }}
                >
                  <MotionInView variants={varFadeIn} sx={{ height: '100%' }}>
                    <ImageStyle
                      alt={defaultTo(title, '')}
                      src={img || placeholderImage}
                      imgSx={{ borderRadius: 0, objectFit: getImageFit(imagePosition) }}
                    />
                  </MotionInView>

                  <BackgroundOverlay
                    sx={{
                      ...(showOverlay && {
                        backgroundImage: (theme) =>
                          createOverlayWithSpread(
                            overlayDirection,
                            overlaySpread,
                            isInvalidText(overlayColourFirstRoot) ? theme.palette.grey[900] : overlayColourFirstRoot,
                            isInvalidText(overlayColourSecondRoot) ? theme.palette.grey[900] : overlayColourSecondRoot
                          )
                      })
                    }}
                  />
                </RootImageStyle>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    );
  }, [isMobile, component, handleNavigationClick]);

  return VerticalImageAndTextMemo;
}

export default VerticalImageAndText;
