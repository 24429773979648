import React from 'react';
import { defaultTo } from 'lodash';
import { Button, Divider, Stack, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { alpha, styled } from '@material-ui/core/styles';
import { BookingLocationItem } from './preview/BookingLocationItem';
import { BookingServicesTimeline } from './preview/BookingServicesTimeline';
import { BookingSlotItem } from './preview/BookingSlotItem';
import { BookingSummary } from './preview/BookingSummary';

const BookingPanelRootStyle = styled(Stack)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`
}));

const BookingPanelView = ({
  currency: currencyRoot,
  isLoading,
  hasError,
  showSummary,
  showSummaryLocation,
  summary,
  location: locationRoot,
  services: servicesRoot,
  slot: slotRoot
}) => {
  const hasSummary = !!summary && !hasError;
  const summaryServices = defaultTo(summary?.invoice?.items, []).map((service) => ({
    id: service.id,
    name: service.name,
    price: service.total,
    duration: service.duration
  }));
  const services = hasSummary ? summaryServices : servicesRoot;
  const location = hasSummary ? summary?.location : locationRoot;
  const slot = hasSummary ? summary?.slot : slotRoot;
  const currency = hasSummary ? summary?.invoice?.currency : currencyRoot;
  return (
    <BookingPanelRootStyle divider={<Divider orientation="horizontal" />}>
      {showSummaryLocation && <BookingLocationItem loading={isLoading} location={location} />}
      <BookingServicesTimeline loading={isLoading} currency={currency} services={services} />
      {slot && <BookingSlotItem loading={isLoading} slot={slot} />}
      {showSummary && <BookingSummary loading={isLoading} summary={summary} hasError={hasError} />}
    </BookingPanelRootStyle>
  );
};

export const BookingPreviewPanel = ({
  config,
  isMobile,
  isLastStep,
  isLoading,
  isComplete,
  isSummaryLoading,
  isPaymentsLoading,
  hasError,
  summary,
  shouldDisableNext,
  shouldDisablePrevious,
  handleNextPage,
  handlePreviousPage,
  ...others
}) => {
  // Disable summary as there is nothing to show
  if (isComplete && summary === false) {
    return null;
  }

  const showSummaryLocation = config?.showLocation;

  return (
    <Stack spacing={2} sx={{ ...(isMobile && { p: (theme) => theme.spacing(0, 2) }) }}>
      <Typography variant="h6">Booking Summary</Typography>
      <BookingPanelView
        showSummary={isLastStep}
        showSummaryLocation={showSummaryLocation}
        summary={summary}
        isLoading={isSummaryLoading}
        hasError={hasError}
        {...others}
      />
      {!isComplete && (
        <>
          <LoadingButton
            loading={isLoading || isSummaryLoading || isPaymentsLoading}
            disabled={shouldDisableNext()}
            color="primary"
            variant="contained"
            onClick={handleNextPage}
          >
            {isLastStep ? 'Complete Booking' : 'Next'}
          </LoadingButton>
          <Button
            disabled={isLoading || isSummaryLoading || isPaymentsLoading || shouldDisablePrevious()}
            color="primary"
            variant="outlined"
            onClick={handlePreviousPage}
          >
            Back
          </Button>
        </>
      )}
    </Stack>
  );
};
