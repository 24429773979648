import { defaultTo, max, min } from 'lodash';

export const BOOKING_STEPS = ['Locations', 'Services', 'Availability', 'Contact Information'];

// Last step is the payment page. The completed transaction page is NOT treated as a step
export const shouldHideLocationsPage = (locations, isLoading) => defaultTo(locations, []).length === 1 && !isLoading;
export const isLastStep = (activeStep) => activeStep === BOOKING_STEPS.length - 1;
export const isFirstStep = (activeStep) => activeStep === 0;
export const nextBookingPage = (step) => min([step + 1, BOOKING_STEPS.length - 1]);
export const previousBookingPage = (step) => max([0, step - 1]);
export const getActiveStep = (step) => BOOKING_STEPS[step];

export const BOOKING_LOCATION_STEP = 0;
export const BOOKING_ITEMS_STEP = 1;
export const BOOKING_AVAILABILITY_STEP = 2;
export const BOOKING_COMPLETE_STEP = 3;

export const buildBookingRequest = (id, currency, values) => {
  return {
    bookingId: id,
    locationId: values.location?.id,
    items: defaultTo(values.services, []).map((service) => ({ id: service.id })),
    currency: currency?.code,
    timestamp: values.slot?.timestamp,
    customer: {
      email: values.email,
      emailCommunicationAccepted: values.emailCommunicationAccepted,
      firstName: values.firstName,
      lastName: values.lastName,
      ...(values.number && { number: `${values.numberCode?.dial_code}${values.number}` })
    },
    note: values.notes,
    paymentOption: values.payment,
    questions: values.questions
  };
};

export const buildBookingSummaryRequest = (id, currency, locationId, services, slotTimestamp) => {
  return {
    bookingId: id,
    locationId,
    timestamp: slotTimestamp,
    items: defaultTo(services, []).map((service) => ({ id: service.id })),
    currency
  };
};
