import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Container, Box, Stack } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import { defaultTo } from 'lodash';
import { getComponentPadding, getContainerWidth } from '../../../utils/getPadding';
import { getHorizontalPosition, getImagePosition, getVerticalPosition } from '../../../utils/getPositions';
import { MLazyLoadImage } from '../../core/@react-lazy-load-image-component-extend';
import placeholderImage from '../../../static/placeholder/Image_and_text.jpg';
import LayoutOrientation from '../../core/LayoutOrientation';
import { getSelectedImage } from '../../../utils/getSelectedImage';
import { MarkdownTextBlock } from '../../core/TextBlock';
import { createOverlayWithSpread } from '../../../utils/createOverlay';
import { DEFAULT_IMAGE_HEIGHT, IMAGE_AND_TEXT_VIEW_TYPE } from '../../../utils/constants';
import { isInvalidText } from '../../../utils/nullable';

export const AspectRatio = AspectRatioPrimitive;

const HEIGHT_CONSTRAINT_STYLE = {
  minHeight: '400px',
  maxHeight: '850px'
};

const ComponentContainer = styled(Box)(() => ({
  position: 'relative'
}));

const Canvas = styled(Box)(() => ({
  position: 'relative',
  ...HEIGHT_CONSTRAINT_STYLE
}));

const ImageWrapper = styled(Box)(() => ({
  overflowY: 'hidden'
}));

const ImageContainer = styled(MLazyLoadImage)(() => ({
  width: '100%',
  height: '100%',
  ...HEIGHT_CONSTRAINT_STYLE
}));

const BackgroundOverlay = styled(Box)(() => ({
  top: 0,
  zIndex: 2,
  width: '100%',
  height: '100%',
  position: 'absolute'
}));

const RootContent = styled(Box)({
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 3,
  width: '100%',
  position: 'absolute'
});

const Content = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'relative'
});

const ImageAndTextCommon = ({ verticalAnimation, isMobile, component, handleNavigationClick }) => {
  const {
    title,
    contentPosition: contentPositionDesktop,
    mobileContentPosition: contentPositionMobile,
    viewType: viewTypeRoot,
    container,
    showOverlay,
    overlayDirection,
    overlaySpread,
    overlayColourFirst: overlayColourFirstRoot,
    overlayColourSecond: overlayColourSecondRoot,
    mobileFixedImageHeight,
    mobileFixedImageHeightValue,
    mobileFixedImageHeightPosition,
    fixedImageHeight,
    fixedImageHeightValue,
    fixedImageHeightPosition,
    desktopImage,
    mobileImage,
    desktopSpacing,
    mobileSpacing,
    navigation
  } = component;
  const contentPosition = isMobile ? defaultTo(contentPositionMobile, contentPositionDesktop) : contentPositionDesktop;
  const viewType = defaultTo(viewTypeRoot, IMAGE_AND_TEXT_VIEW_TYPE[0]);
  const fixedHeight = isMobile ? mobileFixedImageHeight : fixedImageHeight;
  const imageHeight = isMobile ? mobileFixedImageHeightValue : fixedImageHeightValue;
  const imageHeightPosition = isMobile ? mobileFixedImageHeightPosition : fixedImageHeightPosition;
  const img = getSelectedImage(isMobile, desktopImage, mobileImage);
  const containerWidth = getContainerWidth(container, isMobile);

  return (
    <ComponentContainer>
      <Stack spacing={0} direction="column">
        <Canvas>
          <ImageWrapper>
            {fixedHeight ? (
              <ImageContainer
                alt={defaultTo(title, '')}
                src={img || placeholderImage}
                imgSx={{
                  height: defaultTo(imageHeight, DEFAULT_IMAGE_HEIGHT),
                  borderRadius: 0,
                  objectPosition: getImagePosition(imageHeightPosition)
                }}
              />
            ) : (
              <AspectRatio.Root ratio={isMobile ? 3 / 4 : 16 / 9}>
                <ImageContainer alt={defaultTo(title, '')} src={img || placeholderImage} imgSx={{ borderRadius: 0 }} />
              </AspectRatio.Root>
            )}
            <BackgroundOverlay
              sx={{
                ...(showOverlay && {
                  backgroundImage: (theme) =>
                    createOverlayWithSpread(
                      overlayDirection,
                      overlaySpread,
                      isInvalidText(overlayColourFirstRoot) ? theme.palette.grey[900] : overlayColourFirstRoot,
                      isInvalidText(overlayColourSecondRoot) ? theme.palette.grey[900] : overlayColourSecondRoot
                    )
                })
              }}
            />
          </ImageWrapper>
          {viewType === IMAGE_AND_TEXT_VIEW_TYPE[0] && (
            <RootContent>
              <Container
                maxWidth={containerWidth}
                sx={{
                  position: 'relative',
                  height: '100%',
                  '&.MuiContainer-root': {
                    ...(!containerWidth && { paddingLeft: 0, paddingRight: 0 })
                  }
                }}
              >
                <Content
                  sx={{ padding: (theme) => getComponentPadding(desktopSpacing, mobileSpacing, isMobile, theme) }}
                >
                  <LayoutOrientation
                    verticalPosition={getVerticalPosition(contentPosition)}
                    horizontalPosition={getHorizontalPosition(contentPosition)}
                  >
                    <MarkdownTextBlock
                      {...component}
                      verticalAnimation={verticalAnimation}
                      position={getHorizontalPosition(contentPosition)}
                      onButtonClick={() => handleNavigationClick(navigation?.type, navigation?.path)}
                    />
                  </LayoutOrientation>
                </Content>
              </Container>
            </RootContent>
          )}
        </Canvas>
        {viewType === IMAGE_AND_TEXT_VIEW_TYPE[1] && (
          <Box>
            <Container
              maxWidth={containerWidth}
              sx={{
                '&.MuiContainer-root': {
                  ...(!containerWidth && { paddingLeft: 0, paddingRight: 0 })
                }
              }}
            >
              <Content
                sx={{
                  position: 'relative',
                  padding: (theme) => getComponentPadding(desktopSpacing, mobileSpacing, isMobile, theme)
                }}
              >
                <LayoutOrientation
                  verticalPosition={getVerticalPosition(contentPosition)}
                  horizontalPosition={getHorizontalPosition(contentPosition)}
                >
                  <MarkdownTextBlock
                    {...component}
                    verticalAnimation={verticalAnimation}
                    position={getHorizontalPosition(contentPosition)}
                    onButtonClick={() => handleNavigationClick(navigation?.type, navigation?.path)}
                  />
                </LayoutOrientation>
              </Content>
            </Container>
          </Box>
        )}
      </Stack>
    </ComponentContainer>
  );
};

export default ImageAndTextCommon;
