import React from 'react';
import { Box, Stack, Typography } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import Markdown from '../../core/Markdown';

const SectionRootStyle = styled(Box)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`,
  padding: theme.spacing(2)
}));

export const BookingReviewSection = ({ title, description, children }) => {
  return (
    <SectionRootStyle>
      <Stack spacing={1}>
        <Typography variant="h6">{title}</Typography>
        {description && <Markdown children={description} />}
        {children}
      </Stack>
    </SectionRootStyle>
  );
};
