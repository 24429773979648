import React from 'react';
import { paramCase } from 'change-case';
import { KLARNA_PAYMENT_PROVIDER_KEY, STRIPE_PAYMENT_PROVIDER_KEY } from '../../../utils/payments';
import { KlarnaPaymentMethod } from './KlarnaPaymentMethod';
import { StripePaymentMethod } from './StripePaymentMethod';
import { StandardPaymentMethod } from './StandardPaymentMethod';

const props = {
  size: { xs: 12 }
};

export const PaymentMethodList = ({ options, ...other }) => {
  return options.map((option, index) => {
    switch (paramCase(option.key)) {
      case paramCase(KLARNA_PAYMENT_PROVIDER_KEY):
        return <KlarnaPaymentMethod key={index} option={option} {...props} {...other} />;
      case paramCase(STRIPE_PAYMENT_PROVIDER_KEY): {
        return <StripePaymentMethod key={index} option={option} {...props} {...other} />;
      }
      default:
        return <StandardPaymentMethod key={index} option={option} {...props} {...other} />;
    }
  });
};
