// GLOBAL
export const TEXTFIELD_SHAPE = ['STANDARD', 'FILLED', 'OUTLINED'];

// HEADER / ANNOUNCEMENT BAR
export const ANNOUNCEMENT_BAR_POSITION = ['TOP', 'TOP_STICKY', 'BOTTOM', 'BOTTOM_STICKY'];
export const NAVIGATION_VIEW_TYPE = ['STANDARD', 'COMPACT'];
export const HEADER_APPEARANCE = ['STANDARD', 'STANDARD_STICKY', 'OVERLAP', 'OVERLAP_STICKY'];
export const HEADER_APPEARANCE_FILL = ['TRANSPARENT', 'TRANSLUCENT', 'SCROLL_FILL', 'SCROLL_TRANSLUCENT'];

// HOME
export const IMAGE_AND_TEXT_VIEW_TYPE = ['COMPACT', 'STANDARD'];

// FOOTER
export const DEFAULT_ADDRESS_HEADING_TEXT = 'Address';
export const DEFAULT_EMAIL_HEADING_TEXT = 'Email';
export const DEFAULT_REGISTRATION_HEADING_TEXT = 'Registration No';
export const SUBSCRIPTION_VIEW_POSITION = ['STANDARD', 'TOP'];
export const CURRENCY_SELECTOR_VIEW_POSITION = ['BOTTOM', 'TOP'];

// PRODUCT FOOTER
export const TAB_FOOTER_VIEW = 'TAB';
export const DROPDOWN_FOOTER_VIEW = 'DROPDOWN';
export const TAB_POSITIONS = ['STANDARD', 'CENTERED'];
export const PRODUCT_FOOTER_DESCRIPTION_TITLE = 'Description';
export const PRODUCT_FOOTER_SHIPPING_TITLE = 'Shipping';
export const PRODUCT_FOOTER_RETURNS_TITLE = 'Returns';

// KEYS / IDS
export const KLARNA_PAYMENTS_CONTAINER_ID = 'klarna-payments-container';

// CHECKOUT STATUS
export const CHECKOUT_SUCCESS_STATUS = 'success';
export const CHECKOUT_PAYMENT_FAILURE_STATUS = 'payment_failed';
export const CHECKOUT_POST_PAYMENT_FAILURE_STATUS = 'post_payment_failed';
export const CHECKOUT_PROCESSING_STATUS = 'processing';
export const CHECKOUT_UNKNOWN_FAILURE_STATUS = 'unknown';

// BOOKING STATUS
export const BOOKING_SUCCESS_STATUS = 'success';
export const BOOKING_PAYMENT_FAILURE_STATUS = 'payment_failed';
export const BOOKING_POST_PAYMENT_FAILURE_STATUS = 'post_payment_failed';
export const BOOKING_PROCESSING_STATUS = 'processing';
export const BOOKING_UNKNOWN_FAILURE_STATUS = 'unknown';

// PRODUCT
export const PRODUCTS_TABLE_VIEW_TYPE = ['STANDARD', 'WIDE'];
export const PRODUCT_VARIANT_VIEW_TYPE = ['DROPDOWN', 'SELECT'];
export const PRODUCTS_ITEM_DESCRIPTION_VIEW_TYPE = ['TEXT', 'DROPDOWN'];
export const VERTICAL_MOBILE_SCROLL_DIRECTION = 'VERTICAL';
export const HORIZONTAL_MOBILE_SCROLL_DIRECTION = 'HORIZONTAL';
export const DEFAULT_PRODUCT_PRICE_SALE_CHANGE_SUPPORTING_TEXT = 'Save';

// FILTERS
export const FILTERS_STATUS_KEY = 'status';
export const FILTERS_COLOURS_KEY = 'colours';
export const FILTERS_SIZES_KEY = 'sizes';
export const FILTERS_PRICES_KEY = 'prices';

// NAVIGATION
export const STANDARD_NAVIGATION_VIEW = 'STANDARD';
export const BUTTON_NAVIGATION_VIEW = 'BUTTON';
export const TEXT_NAVIGATION_VIEW = 'TEXT';

// CONTACT
export const CONTACT_VIEW_DIRECTION = ['VERTICAL', 'HORIZONTAL'];
export const CONTACT_VERTICAL_VIEW_TYPE = ['STANDARD', 'WIDE'];
export const CONTACT_HORIZONTAL_VIEW_TYPE = ['LEFT', 'RIGHT'];

// CART
export const CART_PREVIEW_VIEW_TYPE = ['STANDARD', 'COMPACT'];
export const CART_BUTTON_VIEW_TYPE = ['TEXT', 'REMOVE', 'BIN'];

// CHECKOUT
export const TEXT_VIEW_DIRECTION = ['STANDARD', 'TOP'];
export const CHECKOUT_SELECTION_VIEW_TYPE = ['CHECKBOX', 'SWITCH'];
export const GENERIC_FONT_SIZE = ['SMALL', 'LARGE'];
export const GENERIC_CURRENCY_VIEW = ['CODE', 'SYMBOL'];
export const CHECKOUT_VIEW_TYPE = ['STANDARD', 'COMPACT'];
