import React from 'react';
import { Stack } from '@material-ui/core';
import {
  DEFAULT_BOOKING_FAILURE_POST_PAYMENT_NEXT_STEPS_TITLE,
  DEFAULT_BOOKING_FAILURE_POST_PAYMENT_NEXT_STEPS_MESSAGE,
  DEFAULT_BOOKING_FAILURE_POST_PAYMENT_TITLE,
  DEFAULT_BOOKING_FAILURE_POST_PAYMENT_MESSAGE
} from '../../../../utils/constants';
import { BookingSummaryHeading } from '../../components/BookingSummaryHeading';
import { BookingReviewSection } from '../../components/BookingReviewSection';

export const BookingPostPaymentFailurePage = ({ config }) => {
  const title = config?.summary?.postPaymentFailure?.title;
  const message = config?.summary?.postPaymentFailure?.message;
  const nextStepsTitle = config?.summary?.postPaymentFailure?.nextStepsTitle;
  const nextStepsMessage = config?.summary?.postPaymentFailure?.nextStepsMessage;
  return (
    <Stack spacing={3}>
      <BookingSummaryHeading
        title={title || DEFAULT_BOOKING_FAILURE_POST_PAYMENT_TITLE}
        description={message || DEFAULT_BOOKING_FAILURE_POST_PAYMENT_MESSAGE}
        isSuccess={false}
      />
      <Stack spacing={2}>
        <BookingReviewSection
          title={nextStepsTitle || DEFAULT_BOOKING_FAILURE_POST_PAYMENT_NEXT_STEPS_TITLE}
          description={nextStepsMessage || DEFAULT_BOOKING_FAILURE_POST_PAYMENT_NEXT_STEPS_MESSAGE}
        />
      </Stack>
    </Stack>
  );
};
