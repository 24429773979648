import React from 'react';
import { StripeElement } from './StripeElement';
import { StandardPaymentMethod } from './StandardPaymentMethod';
import { PaymentLoadingView } from './PaymentLoadingView';

export const StripePaymentMethod = ({ option, handleUpdateStripeContext, ...other }) => {
  return (
    <StandardPaymentMethod option={option} {...other}>
      {option.element ? (
        <StripeElement
          config={option.element}
          loader={PaymentLoadingView}
          handleUpdateStripeContext={handleUpdateStripeContext}
        />
      ) : (
        PaymentLoadingView
      )}
    </StandardPaymentMethod>
  );
};
