import React from 'react';
// import { getTextAlignPosition } from '../../../utils/getPositions';
import { Typography } from '@material-ui/core';

export const BookingTitle = ({
  // position,
  sx,
  ...other
}) => (
  <Typography
    variant="h6"
    color="primary"
    sx={{
      px: 1,
      mb: 2,
      // textAlign: getTextAlignPosition(position),
      textAlign: 'start',
      ...sx
    }}
    {...other}
  />
);
