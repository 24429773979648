import React, { useEffect, useMemo, useRef, useState } from 'react';
import { defaultTo, first, isEmpty } from 'lodash';
import { useInView } from 'react-intersection-observer';
import {
  Divider,
  Tab,
  Tabs,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Box,
  Typography
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { getComponentPadding } from '../../utils/getPadding';
import Markdown from '../core/Markdown';
import Scrollbar from '../core/Scrollbar';
import useMobile from '../../hooks/useMobile';
import {
  DROPDOWN_FOOTER_VIEW,
  MOBILE_CONTAINER_LEFT_RIGHT_PADDING,
  PRODUCT_FOOTER_DESCRIPTION_TITLE,
  PRODUCT_FOOTER_RETURNS_TITLE,
  PRODUCT_FOOTER_SHIPPING_TITLE,
  TAB_FOOTER_VIEW,
  TAB_POSITIONS
} from '../../utils/constants';

const Content = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(5)
}));

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: theme.spacing(0),
    width: '100%'
  },
  '&.MuiAccordion-root:before': {
    display: 'none'
  }
}));

const AccordionSummaryStyle = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(0.75, MOBILE_CONTAINER_LEFT_RIGHT_PADDING)
}));

const AccordionDetailsStyle = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(MOBILE_CONTAINER_LEFT_RIGHT_PADDING, MOBILE_CONTAINER_LEFT_RIGHT_PADDING)
}));

const DESCRIPTION_KEY = 'description';
const SHIPPING_KEY = 'shipping';
const RETURNS_KEY = 'returns';

const PlaceholderText = ({ value }) => (
  <Box sx={{ width: '100%', textAlign: 'center' }}>
    <Typography variant="body1" color="text.secondary">
      {value}
    </Typography>
  </Box>
);

const ProductFooterTabView = ({ isMobile, tabs, config: { tabPosition } }) => {
  const actionRef = useRef();
  const [containerRef, inView] = useInView({
    threshold: 0,
    triggerOnce: true
  });

  const [currentTab, setCurrentTab] = useState(first(tabs)?.key);

  const handleChangeTab = (_, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (inView) {
      try {
        if (actionRef.current) {
          actionRef.current.updateIndicator();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [inView]);

  return (
    !isEmpty(tabs) && (
      <Box ref={containerRef}>
        <Tabs
          action={actionRef}
          centered={Boolean(!isMobile && tabPosition === TAB_POSITIONS[1])}
          variant={isMobile ? 'scrollable' : 'standard'}
          value={currentTab}
          scrollButtons="auto"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.key}
              disableRipple
              label={
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  {tab.title}
                </Typography>
              }
              value={tab.key}
            />
          ))}
        </Tabs>
        <Divider />
        {tabs.map((tab) => {
          const isMatched = tab.key === currentTab;
          return (
            isMatched && (
              <Content key={tab.key}>
                <Scrollbar>{tab.component}</Scrollbar>
              </Content>
            )
          );
        })}
      </Box>
    )
  );
};

const ProductFooterDropdownView = ({ config, tabs }) => {
  const {
    dropdownFirstExpandedByDefault,
    dropdownBackgroundColour,
    dropdownTextColour,
    dropHeaderProminent,
    dropdownShowDivider
  } = config;
  const defaultExpanded = isEmpty(tabs) ? false : defaultTo(tabs[0].key, false);
  const [expanded, setExpanded] = useState(dropdownFirstExpandedByDefault ? defaultExpanded : false);

  const handleChange = (tab) => (_, isExpanded) => {
    setExpanded(isExpanded ? tab : false);
  };

  return (
    <Box>
      <Stack
        spacing={2}
        orientation="horizontal"
        {...(dropdownShowDivider && {
          divider: <Divider sx={{ width: '100%' }} />
        })}
      >
        {tabs.map((tab) => {
          return (
            <AccordionStyle
              key={tab.key}
              disableGutters
              elevation={0}
              expanded={expanded === tab.key}
              aria-controls={`${tab.key}-content`}
              id={`${tab.key}-header`}
              onChange={handleChange(tab.key)}
              sx={{
                '&.MuiAccordion-root': {
                  ...(dropdownBackgroundColour && { backgroundColor: dropdownBackgroundColour }),
                  ...(!isEmpty(dropdownTextColour) && { color: dropdownTextColour })
                }
              }}
            >
              <AccordionSummaryStyle
                expandIcon={
                  <Icon
                    icon={arrowIosDownwardFill}
                    width={20}
                    height={20}
                    color={!isEmpty(dropdownTextColour) ? dropdownTextColour : 'inherit'}
                  />
                }
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: !isEmpty(dropdownTextColour) ? dropdownTextColour : 'text.primary',
                    ...(dropHeaderProminent && { fontWeight: (theme) => theme.typography.fontWeightBold })
                  }}
                >
                  {tab.title}
                </Typography>
              </AccordionSummaryStyle>
              <AccordionDetailsStyle>{tab.component}</AccordionDetailsStyle>
            </AccordionStyle>
          );
        })}
      </Stack>
    </Box>
  );
};

export default function ProductFooter({ product, config }) {
  const isMobile = useMobile();
  const {
    showDescription = true,
    viewType,
    showShippingTab = false,
    showReturnsTab = false,
    descriptionTitle,
    shippingTabTitle,
    returnsTabTitle,
    shippingInformation,
    returnsInformation,
    showDescriptionTab = false,
    desktopFooterContentSpacing: desktopContentSpacing,
    mobileFooterContentSpacing: mobileContentSpacing
  } = config;
  const { description } = product;
  const showDescriptionInTabs = showDescription && showDescriptionTab;

  const tabs = useMemo(() => {
    return [
      {
        enabled: showDescriptionInTabs && !isEmpty(description),
        key: DESCRIPTION_KEY,
        title: isEmpty(descriptionTitle) ? PRODUCT_FOOTER_DESCRIPTION_TITLE : descriptionTitle,
        component: <Markdown children={description} />
      },
      {
        enabled: showShippingTab,
        key: SHIPPING_KEY,
        title: isEmpty(shippingTabTitle) ? PRODUCT_FOOTER_SHIPPING_TITLE : shippingTabTitle,
        component: !isEmpty(shippingInformation) ? (
          <Markdown children={shippingInformation} />
        ) : (
          <PlaceholderText value="There is no shipping information available." />
        )
      },
      {
        enabled: showReturnsTab,
        key: RETURNS_KEY,
        title: isEmpty(returnsTabTitle) ? PRODUCT_FOOTER_RETURNS_TITLE : returnsTabTitle,
        component: !isEmpty(returnsInformation) ? (
          <Markdown children={returnsInformation} />
        ) : (
          <PlaceholderText value="There is no product returns information available." />
        )
      }
    ].filter((obj) => obj.enabled);
  }, [
    descriptionTitle,
    shippingTabTitle,
    returnsTabTitle,
    description,
    returnsInformation,
    shippingInformation,
    showReturnsTab,
    showShippingTab,
    showDescriptionInTabs
  ]);

  return (
    <Box sx={{ padding: (theme) => getComponentPadding(desktopContentSpacing, mobileContentSpacing, isMobile, theme) }}>
      {viewType === TAB_FOOTER_VIEW && <ProductFooterTabView isMobile={isMobile} tabs={tabs} config={config} />}
      {viewType === DROPDOWN_FOOTER_VIEW && (
        <ProductFooterDropdownView isMobile={isMobile} tabs={tabs} config={config} />
      )}
    </Box>
  );
}
