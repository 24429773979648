import React from 'react';
import { useField } from 'formik';
import { FormHelperText, Stack } from '@material-ui/core';
import { DEFAULT_BOOKING_QUESTIONS_TITLE } from '../../utils/constants';
import { BookingDropdownActionView } from './components/BookingDropdownActionView';
import { BookingTitle } from './components/BookingTitle';
import MTextField from '../core/@material-extend/MTextField';

const BookingQuestionItem = ({ shape, item, index, shouldExpand }) => {
  const { id, question } = item;
  const [answerField, context, helpers] = useField({ name: `questions[${index}].answer` });

  return (
    <Stack spacing={1}>
      <BookingDropdownActionView key={id} title={question} defaultExpanded={shouldExpand} variantTitle="subtitle1">
        <MTextField
          shape={shape}
          fullWidth
          multiline
          minRows={3}
          maxRows={15}
          autoComplete="off"
          name={answerField.name}
          value={answerField.value}
          onChange={(e) => {
            helpers.setValue(e.target.value);
          }}
        />
      </BookingDropdownActionView>
      {Boolean(context.touched && context.error) && (
        <FormHelperText error>{context.touched && context.error}</FormHelperText>
      )}
    </Stack>
  );
};

export const BookingQuestions = ({ shape, title, form, shouldExpand }) => {
  const { values } = form;

  return (
    <Stack spacing={1}>
      <BookingTitle sx={{ px: 0, mb: 1 }}>{title || DEFAULT_BOOKING_QUESTIONS_TITLE}</BookingTitle>
      {values.questions.map((obj, index) => {
        return <BookingQuestionItem key={obj.id} shape={shape} item={obj} index={index} shouldExpand={shouldExpand} />;
      })}
    </Stack>
  );
};
