import React, { useCallback, useEffect, useRef } from 'react';
import { defaultTo, find, findIndex, isEmpty, isUndefined } from 'lodash';
import { Box, Container, Divider, Stack } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { getEnabledPayments } from '../../utils/payments';
import useMobile from '../../hooks/useMobile';
import { DEFAULT_EMAIL_COMMS_SUBSCRIPTION, phoneCodes } from '../../utils/constants';
import {
  buildBookingRequest,
  isLastStep,
  nextBookingPage,
  previousBookingPage,
  BOOKING_LOCATION_STEP,
  BOOKING_ITEMS_STEP,
  BOOKING_AVAILABILITY_STEP,
  buildBookingSummaryRequest,
  BOOKING_COMPLETE_STEP,
  isFirstStep
} from '../../utils/bookingUtils';
import { BookingPage } from './BookingPage';
import { BookingHeading } from './BookingHeading';
import { BookingPreviewPanel } from './BookingPreviewPanel';

const PREVIEW_PANEL_WIDTH = 384;

const FlexForm = styled(Form)(() => ({
  flexGrow: 1
}));

const RootStyle = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary
}));

const BookingPageStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 1)
}));

export const BookingView = ({
  context,
  state,
  logo,
  config,
  currency,
  resource, // bookingUuid
  timezone,
  paymentMethods: paymentMethodsRoot,
  hasError,
  handleReset,
  handleChangedPage,
  handleChangePaymentOption: onChangePaymentOption,
  handleCompleteBooking,
  handleGetBookingLocations,
  handleGetBookingServices,
  handleGetBookingAvailability,
  handleGetBookingSummary,
  handleNavigationClick
}) => {
  const isMobile = useMobile();
  const summarydata = state.summary;
  const summaryQuestions = defaultTo(summarydata?.invoice?.questions, []);
  const isBookingSubmitting = state.loading.booking;
  const isSummaryLoading = state.loading.summary;

  const defaultCode = find(phoneCodes, { code: 'GB' });
  const prefill = context?.prefill;
  const shape = config?.global?.theme?.appearance?.input;
  const questionsMap = defaultTo(config?.booking?.general?.questions, []).map((o) => ({ ...o, answer: '' }));
  const previewConfig = config?.booking?.preview;
  const viewOrientation = previewConfig?.switchDesktopOrientation ? 'row-reverse' : 'row';

  // Payments
  const paymentMethods = getEnabledPayments(paymentMethodsRoot);
  const isPaymentsEnabled = defaultTo(state.summary?.payment?.enabled, false);

  // If any of the options are loading then consider them all loading
  const isPaymentsLoading = useCallback(
    (includeElements) => {
      const found = find(defaultTo(paymentMethodsRoot, []), (option) => {
        if (includeElements) {
          return option.enabled && (option.loading || option.element?.loading);
        }
        return option.enabled && option.loading;
      });
      return !isUndefined(found);
    },
    [paymentMethodsRoot]
  );

  // Payment provider elements reference
  const paymentsContext = useRef({
    stripe: {
      elements: null,
      client: null
    }
  });

  const handleUpdateStripeContext = useCallback((elements, client) => {
    paymentsContext.current.stripe = {
      elements,
      client
    };
  }, []);

  const BookingSchema = Yup.object().shape({
    emailCommunicationAccepted: Yup.bool().optional().nullable(),
    email: Yup.string().email().required('Email is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    number: Yup.string().required('Number is required'),
    questions: Yup.array().of(
      Yup.object().shape({
        answer: Yup.string().required('This question is required to complete your booking')
      })
    )
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      activeStep: 0,
      // Booking Location
      hideLocations: false, // When there is only one location we will disable locations selection and choose the first object
      location: null,
      // Booking Service (can select multiple services)
      services: [],
      // Booking Availability Slot
      slot: null,
      // Notes
      notes: '',
      // Information
      emailCommunicationAccepted: defaultTo(prefill?.emailCommunicationAccepted, DEFAULT_EMAIL_COMMS_SUBSCRIPTION),
      email: defaultTo(prefill?.email, ''),
      firstName: defaultTo(prefill?.firstName, ''),
      lastName: defaultTo(prefill?.lastName, ''),
      numberCode: defaultTo(prefill?.defaultCode, defaultCode), // Object for selection
      number: defaultTo(prefill?.number, ''),
      // Payment
      payment: null,
      // Questions
      questions: questionsMap
    },
    validationSchema: BookingSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (isLastStep(values.activeStep)) {
          try {
            const request = buildBookingRequest(resource, currency, values);
            await handleCompleteBooking(request, paymentsContext.current);
          } catch (e) {
            console.error(e);
          }
        }
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    }
  });

  const { values, isSubmitting, setFieldValue, handleSubmit } = formik;

  const handleGetLocations = useCallback(
    (page, size) => handleGetBookingLocations(resource, page, size),
    [resource, handleGetBookingLocations]
  );

  const handleGetServices = useCallback(
    (currency, page, size) => handleGetBookingServices(currency, resource, values.location?.id, page, size),
    [resource, values.location, handleGetBookingServices]
  );

  const handleGetAvailability = useCallback(
    (from, to) => handleGetBookingAvailability(resource, values.location?.id, from, to),
    [resource, values.location, handleGetBookingAvailability]
  );

  const handleGetSummary = useCallback(
    (currency) => {
      if (isSummaryLoading || summarydata !== null) {
        return;
      }
      try {
        const locationId = values.location?.id;
        const services = values.services;
        const slotTimestamp = values.slot?.timestamp;
        const request = buildBookingSummaryRequest(resource, currency, locationId, services, slotTimestamp);
        handleGetBookingSummary(request);
      } catch (e) {
        console.error(e);
      }
    },
    [resource, isSummaryLoading, summarydata, values.location, values.services, values.slot, handleGetBookingSummary]
  );

  const shouldDisableNext = useCallback(() => {
    if (values.activeStep === BOOKING_LOCATION_STEP) {
      return !values.location;
    }
    if (values.activeStep === BOOKING_ITEMS_STEP) {
      return isEmpty(values.services);
    }
    if (values.activeStep === BOOKING_AVAILABILITY_STEP) {
      return true;
    }
    // Missing summary data
    if (values.activeStep === BOOKING_COMPLETE_STEP && summarydata === false) {
      return true;
    }
    const missingPaymentMethods = isPaymentsEnabled && isEmpty(paymentMethods);
    const noSelectedPaymentMethod = isPaymentsEnabled && !values.payment;
    // Payments enabled but not valid
    if (values.activeStep === BOOKING_COMPLETE_STEP && (missingPaymentMethods || noSelectedPaymentMethod)) {
      return true;
    }
    return false;
  }, [
    summarydata,
    values.activeStep,
    values.location,
    values.services,
    values.payment,
    isPaymentsEnabled,
    paymentMethods
  ]);

  const shouldDisablePrevious = useCallback(() => {
    if (values.activeStep === BOOKING_LOCATION_STEP) {
      return true;
    }
    if (values.activeStep === BOOKING_ITEMS_STEP && values.hideLocations) {
      return true;
    }
    return false;
  }, [values.activeStep, values.hideLocations]);

  const handleNext = useCallback(() => {
    // Current step already on last page
    if (values.activeStep === BOOKING_COMPLETE_STEP) {
      handleSubmit();
    } else {
      const nextStep = nextBookingPage(values.activeStep);
      setFieldValue('activeStep', nextStep);
      // Callback with current form
      const request = buildBookingRequest(resource, currency, values);
      handleChangedPage(request, nextStep, isLastStep(nextStep), isFirstStep(nextStep));
    }
  }, [values, resource, currency, handleSubmit, setFieldValue, handleChangedPage]);

  const handleBack = useCallback(() => {
    const nextStep = previousBookingPage(values.activeStep);
    setFieldValue('activeStep', nextStep);
    if (nextStep <= BOOKING_LOCATION_STEP) {
      setFieldValue('location', null);
    }
    if (nextStep <= BOOKING_ITEMS_STEP) {
      setFieldValue('services', []);
    }
    if (nextStep <= BOOKING_AVAILABILITY_STEP) {
      setFieldValue('slot', null);
    }
    if (nextStep <= BOOKING_COMPLETE_STEP) {
      // Reset the checkout stage NOT the form
      handleReset();
    }
    // Callback with current form
    const request = buildBookingRequest(resource, currency, values);
    handleChangedPage(request, nextStep, isLastStep(nextStep), isFirstStep(nextStep));
  }, [values, resource, currency, setFieldValue, handleReset, handleChangedPage]);

  const handleUpdateField = useCallback(
    (field, value) => {
      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  const handleUpdateSlot = useCallback(
    (timestamp) => {
      handleUpdateField('slot', timestamp);
      handleNext();
    },
    [handleUpdateField, handleNext]
  );

  const handleUpdateLocation = useCallback(
    (location, hideLocations) => {
      handleUpdateField('location', location);
      handleUpdateField('hideLocations', hideLocations);
      handleNext();
    },
    [handleUpdateField, handleNext]
  );

  const handleUpdateServices = useCallback(
    (value) => {
      const update = [...values.services];
      const index = findIndex(defaultTo(update, []), { id: value.id });
      if (index >= 0) {
        update.splice(index, 1);
      } else {
        update.push(value);
      }
      handleUpdateField('services', update);
    },
    [values.services, handleUpdateField]
  );

  const handleCheckServiceSelected = useCallback(
    (serviceId) => {
      const index = findIndex(defaultTo(values.services, []), { id: serviceId });
      if (index >= 0) {
        return true;
      }
      return false;
    },
    [values.services]
  );

  const handleChangePaymentOption = useCallback(
    (paymentKey) => {
      onChangePaymentOption(paymentKey);
    },
    [onChangePaymentOption]
  );

  useEffect(() => {
    if (values.activeStep === BOOKING_COMPLETE_STEP) {
      const { code: currencyCode } = currency;
      handleGetSummary(currencyCode);
    }
  }, [values.activeStep, currency, handleGetSummary]);

  return (
    <RootStyle>
      <Container {...(isMobile ? { disableGutters: true } : { maxWidth: 'lg' })}>
        <FormikProvider value={formik}>
          <FlexForm autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={5} direction="column">
              <BookingHeading logo={logo} handleNavigationClick={handleNavigationClick} />
              <Stack
                justifyContent="center"
                spacing={isMobile ? 4 : 0}
                direction={isMobile ? 'column' : viewOrientation}
                {...(isMobile && { divider: <Divider /> })}
                sx={{ width: '100%' }}
              >
                <BookingPageStyle>
                  <BookingPage
                    form={formik}
                    page={values.activeStep}
                    config={config}
                    hasError={hasError}
                    isMobile={isMobile}
                    currency={currency}
                    timezone={timezone}
                    shape={shape}
                    loading={state?.loading}
                    status={state?.status}
                    isComplete={state?.isComplete}
                    isPaymentsLoading={isPaymentsLoading()}
                    isPaymentsEnabled={isPaymentsEnabled}
                    paymentMethods={paymentMethods}
                    questions={summaryQuestions}
                    handleChangePaymentOption={handleChangePaymentOption}
                    handleUpdateStripeContext={handleUpdateStripeContext}
                    handleGetBookingLocations={handleGetLocations}
                    handleGetBookingServices={handleGetServices}
                    handleGetBookingAvailability={handleGetAvailability}
                    handleCheckServiceSelected={handleCheckServiceSelected}
                    handleUpdateSlot={handleUpdateSlot}
                    handleUpdateLocation={handleUpdateLocation}
                    handleUpdateServices={handleUpdateServices}
                    handleNavigationClick={handleNavigationClick}
                  />
                </BookingPageStyle>
                <Box
                  {...(!isMobile && {
                    padding: (theme) => theme.spacing(0, 1),
                    minWidth: PREVIEW_PANEL_WIDTH,
                    maxWidth: PREVIEW_PANEL_WIDTH
                  })}
                >
                  <BookingPreviewPanel
                    config={previewConfig}
                    isMobile={isMobile}
                    isLastStep={isLastStep(values.activeStep) || state.isComplete}
                    shouldDisableNext={shouldDisableNext}
                    shouldDisablePrevious={shouldDisablePrevious}
                    currency={currency}
                    isLoading={isBookingSubmitting || isSubmitting}
                    isComplete={state.isComplete}
                    isSummaryLoading={isSummaryLoading || false}
                    isPaymentsLoading={isPaymentsLoading(true)}
                    summary={summarydata}
                    hasError={summarydata === false}
                    slot={values.slot}
                    location={values.location}
                    services={values.services}
                    handleNextPage={handleNext}
                    handlePreviousPage={handleBack}
                  />
                </Box>
              </Stack>
            </Stack>
          </FlexForm>
        </FormikProvider>
      </Container>
    </RootStyle>
  );
};
