import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import {
  BOOKING_PAYMENT_FAILURE_STATUS,
  BOOKING_POST_PAYMENT_FAILURE_STATUS,
  BOOKING_PROCESSING_STATUS,
  BOOKING_SUCCESS_STATUS,
  BOOKING_UNKNOWN_FAILURE_STATUS,
  DEFAULT_BOOKING_LOADING_SUBMIT_MESSAGE,
  DEFAULT_BOOKING_LOADING_SUMMARY_MESSAGE
} from '../../utils/constants';
import { BookingPayment } from './BookingPayment';
import { BookingLocationsPage } from './pages/BookingLocationsPage';
import { BookingServicesPage } from './pages/BookingServicesPage';
import { BookingAvailabilityPage } from './pages/BookingAvailabilityPage';
import { BookingCompletePage } from './pages/BookingCompletePage';
import { BookingSuccessPage } from './pages/complete/BookingSuccessPage';
import { BookingSuccessProcessingPage } from './pages/complete/BookingSuccessProcessingPage';
import { getActiveStep } from '../../utils/bookingUtils';
import { Loader } from '../core/Loader';
import { BookingPaymentFailurePage } from './pages/complete/BookingPaymentFailurePage';
import { BookingPostPaymentFailurePage } from './pages/complete/BookingPostPaymentFailurePage';
import { BookingUnknownFailurePage } from './pages/complete/BookingUnknownFailurePage';

const RootStyle = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1)
}));

export const BookingPage = ({
  config, // Global
  page,
  loading,
  isPaymentsLoading,
  isComplete,
  status,
  currency,
  hasError,
  isPaymentsEnabled,
  paymentMethods,
  questions,
  loadingMessage,
  handleUpdateStripeContext,
  handleChangePaymentOption,
  ...others
}) => {
  const { form } = others;

  // Config
  const messages = config?.global?.messages?.booking;
  const bookingConfig = config?.booking;
  const loadingSummary = bookingConfig?.general?.bookingSummaryLoadingMessage;
  const loadingSubmit = bookingConfig?.general?.submitBookingLoadingMessage;

  // Loading message
  const summaryMessage = loadingSummary || DEFAULT_BOOKING_LOADING_SUMMARY_MESSAGE;
  const submitMessage = loadingSubmit || DEFAULT_BOOKING_LOADING_SUBMIT_MESSAGE;

  // Events
  const { summary: isSummaryLoading, booking: isSubmitting } = loading;

  if (isSubmitting) {
    return <Loader message={submitMessage} sx={{ height: '200px' }} />;
  }

  if (isSummaryLoading) {
    return <Loader message={summaryMessage} sx={{ height: '200px' }} />;
  }

  if (isComplete) {
    return (
      <RootStyle>
        {status === BOOKING_SUCCESS_STATUS && <BookingSuccessPage config={bookingConfig} questions={questions} />}
        {status === BOOKING_PROCESSING_STATUS && (
          <BookingSuccessProcessingPage config={bookingConfig} questions={questions} />
        )}
        {status === BOOKING_PAYMENT_FAILURE_STATUS && <BookingPaymentFailurePage config={bookingConfig} />}
        {status === BOOKING_POST_PAYMENT_FAILURE_STATUS && <BookingPostPaymentFailurePage config={bookingConfig} />}
        {status === BOOKING_UNKNOWN_FAILURE_STATUS && <BookingUnknownFailurePage config={bookingConfig} />}
      </RootStyle>
    );
  }

  const pageName = getActiveStep(page);

  return (
    <>
      {pageName && (
        <Typography variant="h6" color="primary" sx={{ textAlign: 'start', px: 1, mb: 2 }}>
          {pageName}
        </Typography>
      )}
      <RootStyle>
        {page === 0 && <BookingLocationsPage messages={messages} currency={currency} {...others} />}
        {page === 1 && <BookingServicesPage messages={messages} currency={currency} {...others} />}
        {page === 2 && <BookingAvailabilityPage currency={currency} {...others} />}
        {page === 3 && (
          <BookingCompletePage config={bookingConfig} loadingMessage={submitMessage} {...others}>
            {isPaymentsEnabled && (
              <BookingPayment
                form={form}
                config={{
                  messaging: messages?.payments,
                  ...bookingConfig
                }}
                currency={currency}
                hasError={hasError}
                loading={isPaymentsLoading}
                loadingMessage={loadingMessage}
                paymentMethods={paymentMethods}
                handleChangePaymentOption={handleChangePaymentOption}
                handleUpdateStripeContext={handleUpdateStripeContext}
              />
            )}
          </BookingCompletePage>
        )}
      </RootStyle>
    </>
  );
};
