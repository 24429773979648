import React from 'react';
import { compact, filter, isEmpty } from 'lodash';
import { Box, Divider, Stack, Typography } from '@material-ui/core';
import { fCurrency, fCurrencyCode } from '../../../utils/formatNumber';
import { SkeletonBookingSummary } from '../../core/SkeletonBookingSummary';
import EmptyContent from '../../core/EmptyContent';
import { BookingItemRootStyle } from './BookingItemRootStyle';

const BookingSummaryError = () => (
  <EmptyContent
    title="Something Went Wrong"
    description="At this moment we cannot load your booking summary"
    fontSx={{
      fontSize: (theme) => `${theme.typography.pxToRem(14)} !important`
    }}
    sx={{ minHeight: 'unset' }}
  />
);

const BookingSummaryItem = ({ title, value }) => {
  return (
    <Stack spacing={1} direction="row" justifyContent="space-between">
      <Typography variant="body2" color="text.secondary">
        {title}
      </Typography>
      <Typography variant="body1">
        <strong>{value}</strong>
      </Typography>
    </Stack>
  );
};

export const BookingSummary = ({ loading, summary: data, hasError }) => {
  if (loading) {
    return (
      <Box sx={{ p: 2 }}>
        <SkeletonBookingSummary />
      </Box>
    );
  }

  const invoice = data?.invoice;
  const services = invoice?.items;
  const currencyCode = invoice?.currency?.code;
  const currencySymbol = invoice?.currency?.symbol;
  if (hasError || !invoice || !services) {
    return <BookingSummaryError />;
  }
  const noServicesWithPrice = isEmpty(compact(services.map((o) => o.total)));
  if (noServicesWithPrice) {
    return null;
  }
  const someServicesWithoutPrice = !isEmpty(filter(services, (o) => !o.total));
  const subTotal = invoice?.subTotal?.amount;
  const tax = invoice?.tax?.amount;
  const total = invoice.total?.amount;
  const amountDue = invoice.amountDue?.amount;

  return (
    <Stack divider={<Divider orientation="horizontal" />}>
      <BookingItemRootStyle spacing={0.5}>
        {subTotal && <BookingSummaryItem title="Sub Total" value={fCurrency(subTotal, currencySymbol)} />}
        {tax && <BookingSummaryItem title="Tax" value={fCurrency(tax, currencySymbol)} />}
        {total && <BookingSummaryItem title="Total" value={fCurrency(total, currencySymbol)} />}
      </BookingItemRootStyle>
      <BookingItemRootStyle>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <Typography variant="body1" color="text.secondary">
            Due Today
          </Typography>
          <Typography variant="h6">{fCurrencyCode(amountDue, currencyCode)}</Typography>
        </Stack>
        {someServicesWithoutPrice && (
          <Typography variant="body2" color="text.secondary" textAlign="end">
            Prices may vary
          </Typography>
        )}
      </BookingItemRootStyle>
    </Stack>
  );
};
