import React from 'react';
import { Stack } from '@material-ui/core';
import { isEmpty } from 'lodash';
import {
  DEFAULT_BOOKING_CANCELLLATION_POLICY_MESSAGE,
  DEFAULT_BOOKING_CANCELLLATION_POLICY_TITLE,
  DEFAULT_BOOKING_PAYMENT_PROCESSING_MESSAGE,
  DEFAULT_BOOKING_PAYMENT_PROCESSING_TITLE,
  DEFAULT_BOOKING_PAYMENT_SUCCESS_QUESTIONS_TITLE,
  DEFAULT_BOOKING_SUCCESS_MESSAGE,
  DEFAULT_BOOKING_SUCCESS_TITLE
} from '../../../../utils/constants';
import { BookingSummaryHeading } from '../../components/BookingSummaryHeading';
import { BookingReviewSection } from '../../components/BookingReviewSection';
import { BookingQuestionsView } from '../../components/BookingQuestionsView';

export const BookingSuccessProcessingPage = ({ config, questions }) => {
  const showCancelPolicy = config?.general?.showCancelPolicy;
  const cancelPolicyTitle = config?.general?.cancelPolicyTitle;
  const cancelPolicyMessage = config?.general?.cancelPolicyMessage;
  const title = config?.summary?.processing?.title;
  const message = config?.summary?.processing?.message;
  const paymentTitle = config?.summary?.processing?.paymentTitle;
  const paymentMessage = config?.summary?.processing?.paymentMessage;
  const showQuestions = config?.summary?.success?.showQuestions;
  const questionsTitle = config?.summary?.success?.questionsTitle;

  return (
    <Stack spacing={3}>
      <BookingSummaryHeading
        title={title || DEFAULT_BOOKING_SUCCESS_TITLE}
        description={message || DEFAULT_BOOKING_SUCCESS_MESSAGE}
      />
      <Stack spacing={2}>
        <BookingReviewSection
          title={paymentTitle || DEFAULT_BOOKING_PAYMENT_PROCESSING_TITLE}
          description={paymentMessage || DEFAULT_BOOKING_PAYMENT_PROCESSING_MESSAGE}
        />
        {showCancelPolicy && (
          <BookingReviewSection
            title={cancelPolicyTitle || DEFAULT_BOOKING_CANCELLLATION_POLICY_TITLE}
            description={cancelPolicyMessage || DEFAULT_BOOKING_CANCELLLATION_POLICY_MESSAGE}
          />
        )}
        {showQuestions && !isEmpty(questions) && (
          <BookingReviewSection title={questionsTitle || DEFAULT_BOOKING_PAYMENT_SUCCESS_QUESTIONS_TITLE}>
            <BookingQuestionsView questions={questions} />
          </BookingReviewSection>
        )}
      </Stack>
    </Stack>
  );
};
