import React from 'react';
import {
  DEFAULT_PAYMENT_OPTION_MISSING_TITLE,
  DEFAULT_PAYMENT_OPTION_MISSING_DESCRIPTION
} from '../../../utils/constants';
import EmptyContent from '../EmptyContent';
import { isInvalid } from '../../../utils/nullable';

export const EmptyPaymentMethods = ({ loading, messaging }) => (
  <EmptyContent
    title={isInvalid(messaging?.title) ? DEFAULT_PAYMENT_OPTION_MISSING_TITLE : messaging?.title}
    description={
      isInvalid(messaging?.description) ? DEFAULT_PAYMENT_OPTION_MISSING_DESCRIPTION : messaging?.description
    }
    variant="h6"
    sx={{ ...(loading && { display: 'none' }) }}
  />
);
