import React, { useCallback, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { Stack, Box, Grid, Container } from '@material-ui/core';
import { compact, defaultTo, isEmpty, min, orderBy } from 'lodash';
import { getComponentPadding, getContainerWidth } from '../../utils/getPadding';
import { getTextAlignPosition } from '../../utils/getPositions';
import { getDesktopItemsPerRow } from '../../utils/getItemsPerRow';
import useMobile from '../../hooks/useMobile';
import {
  GALLERY_IMAGES_PLACEHOLDER_HEIGHT,
  DEFAULT_GALLERY_IMAGES_MISSING_DESCRIPTION,
  DEFAULT_GALLERY_IMAGES_MISSING_TITLE,
  DEFAULT_DEKTOP_GALLERY_IMAGE_GRID_SIZE,
  DEFAULT_MOBILE_GALLERY_IMAGE_GRID_SIZE
} from '../../utils/constants';
import { ComponentHeaderV2 } from './ComponentHeader';
import EmptyContent from '../core/EmptyContent';
import { GalleryImageItem } from '../gallery/GalleryImageItem';
import LightboxModal from '../core/product/LightboxModal';
import { MotionInView, varFadeInUp } from '../core/animate';
import { TextButton } from '../core/TextBlock';

const INITIAL_STATE = {
  openLightbox: false,
  selectedImage: 0
};

const ImagesWrapper = styled(Box)(() => ({
  position: 'relative',
  overflowY: 'hidden'
}));

const PlaceholderView = styled(Box)(() => ({
  height: GALLERY_IMAGES_PLACEHOLDER_HEIGHT
}));

function HomeGalleryImages({ component: config, handleNavigationClick = () => {} }) {
  const [state, setState] = useState(INITIAL_STATE);
  const isMobile = useMobile();
  const {
    title,
    caption,
    titleColour,
    captionColour,
    backgroundColour,
    buttonColour,
    buttonTextColour,
    desktopGalleryRows = 2,
    mobileGalleryRows = 1,
    container,
    itemSpacing,
    position: positionRoot,
    showImagePreview: showImagePreviewRoot,
    items: itemsRoot,
    desktopSpacing,
    mobileSpacing,
    buttonText,
    navigation
  } = config;
  const items = defaultTo(itemsRoot, []);
  const numberOfItems = items.length;
  const ordered = orderBy(items, 'order', 'asc');
  const showHeader = !isEmpty(caption) || !isEmpty(title);
  const gridSpacing = defaultTo(itemSpacing, 1);
  const position = getTextAlignPosition(positionRoot);
  const showImagePreview = defaultTo(showImagePreviewRoot, true);
  const containerWidth = getContainerWidth(container, isMobile);

  const images = compact(items.map((item) => item.desktopImage));

  const getMobileRows = useCallback(
    () => (mobileGalleryRows ? 12 / min([mobileGalleryRows, numberOfItems]) : DEFAULT_MOBILE_GALLERY_IMAGE_GRID_SIZE),
    [mobileGalleryRows, numberOfItems]
  );

  const getDesktopRows = useCallback(
    () =>
      desktopGalleryRows
        ? getDesktopItemsPerRow(desktopGalleryRows, numberOfItems)
        : DEFAULT_DEKTOP_GALLERY_IMAGE_GRID_SIZE,
    [desktopGalleryRows, numberOfItems]
  );

  const handleOpenPreview = useCallback(
    (image) => {
      if (showImagePreview) {
        const index = images.findIndex((_image) => _image === image);
        if (index >= 0) {
          setState({
            selectedImage: index,
            openLightbox: true
          });
        }
      }
    },
    [showImagePreview, images, setState]
  );

  const gridSizing = {
    xs: isMobile ? getMobileRows() : getDesktopRows()
  };

  return (
    <Box sx={{ ...(backgroundColour && { backgroundColor: backgroundColour }) }}>
      <LightboxModal
        images={images}
        photoIndex={state.selectedImage}
        setPhotoIndex={(_index) => setState((prev) => ({ ...prev, selectedImage: _index }))}
        isOpen={state.openLightbox}
        onClose={() => setState({ openLightbox: false })}
      />
      <Container
        maxWidth={containerWidth}
        sx={{
          position: 'relative',
          height: '100%',
          '&.MuiContainer-root': {
            ...(!containerWidth && { paddingLeft: 0, paddingRight: 0 })
          }
        }}
      >
        <Box sx={{ padding: (theme) => getComponentPadding(desktopSpacing, mobileSpacing, isMobile, theme) }}>
          <Stack spacing={6}>
            {showHeader && (
              <ComponentHeaderV2
                caption={caption}
                title={title}
                titleColour={titleColour}
                captionColour={captionColour}
              />
            )}
            {items && !isEmpty(items) ? (
              <ImagesWrapper>
                <Grid container direction="row" spacing={gridSpacing} justifyContent={position}>
                  {ordered.map((_item) => (
                    <Grid key={_item.id} item {...gridSizing}>
                      <GalleryImageItem
                        isMobile={isMobile}
                        showImagePreview={showImagePreview}
                        item={_item}
                        handleOpenPreview={handleOpenPreview}
                        handleNavigationClick={handleNavigationClick}
                      />
                    </Grid>
                  ))}
                </Grid>
              </ImagesWrapper>
            ) : (
              <PlaceholderView>
                <EmptyContent
                  title={DEFAULT_GALLERY_IMAGES_MISSING_TITLE}
                  description={DEFAULT_GALLERY_IMAGES_MISSING_DESCRIPTION}
                  sx={{ height: '100%' }}
                />
              </PlaceholderView>
            )}

            {!isEmpty(buttonText) && (
              <MotionInView variants={varFadeInUp}>
                <Box sx={{ display: 'flex', justifyContent: position }}>
                  <TextButton
                    buttonText={buttonText}
                    buttonColour={buttonColour}
                    buttonTextColour={buttonTextColour}
                    onButtonClick={() => handleNavigationClick(navigation?.type, navigation?.path)}
                  />
                </Box>
              </MotionInView>
            )}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default HomeGalleryImages;
