import React, { useMemo } from 'react';
import { Box, FormHelperText, Grid, RadioGroup, Stack } from '@material-ui/core';
import { isEmpty } from 'lodash';
import useMobile from '../../hooks/useMobile';
import { getEnabledPayments } from '../../utils/payments';
import { Loader } from '../core/Loader';
import { DiscountInput } from './DiscountInput';
import { CheckoutTitle } from './components/CheckoutTitle';
import { PrePaymentCheckoutError } from '../core/payment/PrePaymentCheckoutError';
import { PaymentMethodList } from '../core/payment/PaymentMethodList';
import { EmptyPaymentMethods } from '../core/payment/EmptyPaymentMethod';

// checkoutError - Pre payment checkout exception. (Not post checkout exception )
export const PaymentPage = ({
  config: { input, general, payments, preview, messaging },
  form,
  loading: loadingData,
  loadingDiscount,
  paymentMethods: data,
  discountStore,
  handleDiscountCheck: onValidateDiscount,
  onClearDiscount,
  hasCheckoutError,
  handleChangePaymentOption,
  handleUpdateStripeContext
}) => {
  const titlePosition = general?.titlePosition;
  const titleSize = general?.titleSize;
  const paymentMethodsTitle = payments?.paymentMethodsTitle;
  const { setFieldValue, getFieldProps, touched, errors } = form;

  const discountProps = getFieldProps('discount');
  const { isValidDiscount } = discountStore;

  const {
    payments: { enable: isError, message }
  } = hasCheckoutError;
  const {
    options: { payments: loading }
  } = loadingData;

  const isMobile = useMobile();
  const showDiscount = preview.showDiscount && isMobile;
  const paymentMethods = getEnabledPayments(data);

  const Title = (props) => <CheckoutTitle position={titlePosition} size={titleSize} {...props} />;

  const DiscountMemo = useMemo(() => {
    return (
      <Stack spacing={2}>
        <CheckoutTitle position={titlePosition} size={titleSize}>
          Discount Code
        </CheckoutTitle>
        <DiscountInput
          input={input}
          isValidDiscount={isValidDiscount}
          discountProps={{
            value: discountProps.value,
            name: discountProps.name,
            onChange: discountProps.onChange,
            onBlur: discountProps.onBlur
          }}
          touched={touched.discount}
          errors={errors.discount}
          loading={loadingDiscount}
          validateDiscount={onValidateDiscount}
          onClickClearDiscount={onClearDiscount}
        />
      </Stack>
    );
  }, [
    titlePosition,
    titleSize,
    input,
    isValidDiscount,
    discountProps.value,
    discountProps.name,
    discountProps.onChange,
    discountProps.onBlur,
    touched.discount,
    errors.discount,
    loadingDiscount,
    onValidateDiscount,
    onClearDiscount
  ]);

  return (
    <>
      {loading && <Loader message="Just a moment, almost there..." sx={{ height: '200px' }} />}
      {isEmpty(paymentMethods) ? (
        <EmptyPaymentMethods loading={loading} messaging={messaging} />
      ) : (
        <>
          <Stack spacing={2} sx={{ ...(loading && { display: 'none' }) }}>
            {isError && <PrePaymentCheckoutError message={message} />}
            <Stack spacing={8} sx={{ ...(loading && { display: 'none' }) }}>
              {showDiscount && DiscountMemo}
              <Stack spacing={2}>
                <Title>{paymentMethodsTitle || 'Payment Methods'}</Title>
                <RadioGroup
                  {...getFieldProps('payment')}
                  onChange={(e) => {
                    const paymentKey = e.target.value;
                    setFieldValue('payment', paymentKey);
                    handleChangePaymentOption(paymentKey);
                  }}
                >
                  <Box>
                    <Grid container spacing={2} direction="column">
                      <PaymentMethodList
                        config={payments}
                        options={paymentMethods}
                        form={form}
                        handleUpdateStripeContext={handleUpdateStripeContext}
                      />
                    </Grid>
                  </Box>
                </RadioGroup>
                {Boolean(touched.payment && errors.payment) && (
                  <FormHelperText error>{touched.payment && errors.payment}</FormHelperText>
                )}
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};
