import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { defaultTo, isEmpty } from 'lodash';
import missingImageDefault from '../../static/placeholder/product_image.jpg';
import useMobile from '../../hooks/useMobile';
import { FEATURED_PRODUCT_PLACEHOLDER_HEIGHT } from '../../utils/constants';
import ProductSumary from '../core/product/ProductSumary';
import ProductHeroCarousel from '../core/product/ProductHeroCarousel';
import { varFadeInRight, MotionInView, varFadeInLeft } from '../core/animate';
import ProductFooter from './ProductFooter';
import { gridSizing } from '../../utils/grid';

const PlaceholderView = styled(Box)(() => ({
  height: FEATURED_PRODUCT_PLACEHOLDER_HEIGHT
}));

export const ProductView = ({
  isWidget,
  config,
  currency,
  product,
  messagingPlacements,
  handleProductChange,
  handleIsAddedToCart,
  handleAddToCart,
  handleRemoveFromCart,
  handleBuyNow,
  handleNavigationClick
}) => {
  const isMobile = useMobile();
  const { switchDesktopOrientation } = config;

  return product ? (
    <Box>
      <Grid container direction={switchDesktopOrientation ? 'row-reverse' : 'row'}>
        <Grid item {...gridSizing(isMobile)}>
          <MotionInView variants={varFadeInLeft}>
            <ProductHeroCarousel
              isMobile={isMobile}
              config={config}
              name={product?.name}
              images={isEmpty(defaultTo(product.images, [])) ? [missingImageDefault] : product.images}
            />
          </MotionInView>
        </Grid>
        <Grid item {...gridSizing(isMobile)}>
          <MotionInView variants={varFadeInRight}>
            <ProductSumary
              isWidget={isWidget}
              currency={currency}
              product={product}
              messagingPlacements={messagingPlacements}
              handleProductChange={handleProductChange}
              handleIsAddedToCart={handleIsAddedToCart}
              handleAddToCart={handleAddToCart}
              handleRemoveFromCart={handleRemoveFromCart}
              handleBuyNow={handleBuyNow}
              handleNavigationClick={handleNavigationClick}
              config={config}
            />
          </MotionInView>
        </Grid>
        {!isWidget && (
          <Grid item xs={12}>
            <MotionInView variants={varFadeInLeft}>
              <ProductFooter product={product} handleNavigationClick={handleNavigationClick} config={config} />
            </MotionInView>
          </Grid>
        )}
      </Grid>
    </Box>
  ) : (
    <PlaceholderView />
  );
};
