import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Stack, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';

const HeadingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}));

export const ComponentHeader = ({ isWideView, caption, title, titleColour, captionColour }) => {
  return (
    <Stack spacing={1} sx={{ ...(isWideView && { paddingTop: (theme) => theme.spacing(10) }) }}>
      {!isEmpty(caption) && (
        <HeadingText
          variant="h6"
          sx={{ textAlign: 'center', color: (theme) => captionColour || theme.palette.text.secondary }}
        >
          {caption}
        </HeadingText>
      )}
      {!isEmpty(title) && (
        <HeadingText variant="h3" sx={{ textAlign: 'center', ...(titleColour && { color: titleColour }) }}>
          {title}
        </HeadingText>
      )}
    </Stack>
  );
};

export const ComponentHeaderV2 = ({ title, caption, titleColour, captionColour }) => {
  return (
    <Stack spacing={1}>
      {!isEmpty(caption) && (
        <HeadingText
          variant="h6"
          sx={{ textAlign: 'center', color: (theme) => captionColour || theme.palette.text.secondary }}
        >
          {caption}
        </HeadingText>
      )}
      {!isEmpty(title) && (
        <HeadingText variant="h3" sx={{ textAlign: 'center', ...(titleColour && { color: titleColour }) }}>
          {title}
        </HeadingText>
      )}
    </Stack>
  );
};
