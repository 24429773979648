import React, { useMemo } from 'react';
import { Box, Container, Stack } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { defaultTo } from 'lodash';
import useMobile from '../../hooks/useMobile';
import { isInvalidText } from '../../utils/nullable';
import { MOBILE_STACK_SPACING } from '../../utils/constants';
import { getHorizontalPosition } from '../../utils/getPositions';
import { MarkdownTextBlock } from '../core/TextBlock';
import { getComponentPadding, getContainerWidth } from '../../utils/getPadding';

const RootStyle = styled(Box)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius
}));

function HomeSimpleText({ disableBorderRadius = true, component, handleNavigationClick }) {
  const isMobile = useMobile();
  const {
    title,
    subtitle,
    caption,
    body,
    showTitle: showHeading,
    titleColour,
    subTitleColour,
    captionColour,
    buttonText,
    buttonColour,
    buttonTextColour,
    backgroundColour,
    container,
    contentPosition: contentPositionDesktop,
    mobileContentPosition: contentPositionMobile,
    navigation,
    desktopSpacing,
    mobileSpacing
  } = component;

  const contentPosition = isMobile ? defaultTo(contentPositionMobile, contentPositionDesktop) : contentPositionDesktop;
  const containerWidth = getContainerWidth(container, isMobile);

  const SimpleBodyTextMemo = useMemo(() => {
    const stackSpacing = isMobile ? MOBILE_STACK_SPACING : 4;

    return (
      <RootStyle
        sx={{
          height: '100%',
          backgroundColor: (theme) =>
            isInvalidText(backgroundColour) ? theme.palette.background.paper : backgroundColour,
          ...(disableBorderRadius && { borderRadius: `0 !important` })
        }}
      >
        <Container
          maxWidth={containerWidth}
          sx={{
            position: 'relative',
            height: '100%',
            '&.MuiContainer-root': {
              ...(!containerWidth && { paddingLeft: 0, paddingRight: 0 })
            }
          }}
        >
          <Stack
            spacing={stackSpacing}
            direction="column"
            alignItems="center"
            sx={{
              padding: (theme) => getComponentPadding(desktopSpacing, mobileSpacing, isMobile, theme)
            }}
          >
            <MarkdownTextBlock
              showHeading={showHeading}
              title={title}
              subtitle={subtitle}
              caption={caption}
              body={body}
              position={getHorizontalPosition(contentPosition)}
              titleColour={titleColour}
              subTitleColour={subTitleColour}
              captionColour={captionColour}
              buttonText={buttonText}
              buttonColour={buttonColour}
              buttonTextColour={buttonTextColour}
              onButtonClick={() => handleNavigationClick(navigation?.type, navigation?.path)}
            />
          </Stack>
        </Container>
      </RootStyle>
    );
  }, [
    disableBorderRadius,
    isMobile,
    title,
    subtitle,
    caption,
    body,
    showHeading,
    titleColour,
    subTitleColour,
    captionColour,
    buttonText,
    buttonColour,
    buttonTextColour,
    backgroundColour,
    containerWidth,
    contentPosition,
    navigation,
    desktopSpacing,
    mobileSpacing,
    handleNavigationClick
  ]);

  return SimpleBodyTextMemo;
}

export default HomeSimpleText;
