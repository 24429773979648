import React, { useCallback, useEffect, useState } from 'react';
import { Stack, Box, Container } from '@material-ui/core';
import { isEmpty } from 'lodash';
import useMobile from '../../hooks/useMobile';
import { ProductView } from '../product/ProductView';
import { ComponentHeaderV2 } from './ComponentHeader';
import { getComponentPadding, getContainerWidth } from '../../utils/getPadding';

export default function HomeFeaturedProduct({
  config,
  currency,
  component,
  handleNavigationClick,
  handleIsAddedToCart,
  handleAddToCart,
  handleRemoveFromCart,
  handleBuyNow,
  handleGetProduct
}) {
  const [product, setProduct] = useState(null);
  const isMobile = useMobile();

  const {
    title = '',
    caption = '',
    titleColour,
    captionColour,
    resource,
    showMobileHeadings,
    container,
    desktopSpacing,
    mobileSpacing
  } = component;
  const isHeaderEmpty = Boolean(isEmpty(caption) && isEmpty(title));
  const hideHeadings = isMobile && !showMobileHeadings;
  const showHeader = Boolean(!hideHeadings && !isHeaderEmpty);
  const appearance = config?.global?.theme?.appearance;
  const containerWidth = getContainerWidth(container, isMobile);

  const getProducts = useCallback(() => {
    handleGetProduct(resource).then((_product) => {
      setProduct(_product);
    });
  }, [resource, handleGetProduct]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <Box>
      <Container
        maxWidth={containerWidth}
        sx={{
          position: 'relative',
          height: '100%',
          '&.MuiContainer-root': {
            ...(!containerWidth && { paddingLeft: 0, paddingRight: 0 })
          }
        }}
      >
        <Box sx={{ padding: (theme) => getComponentPadding(desktopSpacing, mobileSpacing, isMobile, theme) }}>
          <Stack spacing={8}>
            {showHeader && (
              <ComponentHeaderV2
                caption={caption}
                title={title}
                titleColour={titleColour}
                captionColour={captionColour}
              />
            )}
            <ProductView
              isWidget
              product={product}
              currency={currency}
              config={{
                ...component,
                ...config?.global?.theme?.colours?.product,
                appearance
              }}
              handleNavigationClick={handleNavigationClick}
              handleIsAddedToCart={handleIsAddedToCart}
              handleAddToCart={handleAddToCart}
              handleRemoveFromCart={handleRemoveFromCart}
              handleBuyNow={handleBuyNow}
            />
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
