import React, { useMemo } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import { defaultTo, isEmpty, orderBy } from 'lodash';
import { isInvalidText } from '../../utils/nullable';
import { getComponentPadding, getContainerWidth } from '../../utils/getPadding';
import useMobile from '../../hooks/useMobile';
import HomeSimpleText from './HomeSimpleText';

export const AspectRatio = AspectRatioPrimitive;

function HomeMultipleText({ component, handleNavigationClick = () => {} }) {
  const isMobile = useMobile();

  const HomeMultipleTextMemo = useMemo(() => {
    const { items, container, desktopSpacing, mobileSpacing, itemSpacing, backgroundColour } = component;
    const ordered = orderBy(items, 'order', 'asc');
    const containerWidth = getContainerWidth(container, isMobile);
    const gridSizing = {
      xs: isMobile ? 12 : 12 / ordered.length
    };

    return (
      <Box
        sx={{
          backgroundColor: (theme) =>
            isInvalidText(backgroundColour) ? theme.palette.background.paper : backgroundColour
        }}
      >
        <Container
          maxWidth={containerWidth}
          sx={{
            position: 'relative',
            height: '100%',
            '&.MuiContainer-root': {
              ...(!containerWidth && { paddingLeft: 0, paddingRight: 0 })
            }
          }}
        >
          {!isEmpty(ordered) && (
            <Box sx={{ padding: (theme) => getComponentPadding(desktopSpacing, mobileSpacing, isMobile, theme) }}>
              <Grid container spacing={defaultTo(itemSpacing, 0)}>
                {ordered.map((item) => (
                  <Grid item key={item.id} {...gridSizing}>
                    <HomeSimpleText
                      disableBorderRadius={false}
                      component={{
                        ...item,
                        backgroundColour: isInvalidText(item.backgroundColour)
                          ? backgroundColour
                          : item.backgroundColour,
                        container: null
                      }}
                      handleNavigationClick={handleNavigationClick}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Container>
      </Box>
    );
  }, [isMobile, component, handleNavigationClick]);

  return HomeMultipleTextMemo;
}

export default HomeMultipleText;
