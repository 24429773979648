import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { BookingTextBlock } from '../BookingTextBlock';
import { SkeletonBookingSummary } from '../../core/SkeletonBookingSummary';
import { BookingItemRootStyle } from './BookingItemRootStyle';
import { EmptyBookingServicesItem } from './EmptyBookingServicesItem';

export const BookingLocationItem = ({ loading, location }) => {
  if (loading) {
    return (
      <Box sx={{ p: 2 }}>
        <SkeletonBookingSummary />
      </Box>
    );
  }
  if (!location) {
    return <EmptyBookingServicesItem title="Please choose a location" />;
  }
  const { name, address, number } = location;
  return (
    <BookingItemRootStyle spacing={1}>
      <Typography variant="overline" color="text.secondary">
        LOCATION
      </Typography>
      <BookingTextBlock title={name} description={address} supportText={[number]} />
    </BookingItemRootStyle>
  );
};
