import React from 'react';
import { Stack, Typography } from '@material-ui/core';
import Markdown from '../../core/Markdown';

export const BookingSummaryHeading = ({ title, description, isSuccess = true }) => {
  return (
    <Stack spacing={0.5}>
      <Typography variant="h5" color={isSuccess ? 'success.main' : 'error.main'}>
        {title}
      </Typography>
      <Markdown children={description} />
    </Stack>
  );
};
