import React from 'react';
import { Typography, Stack } from '@material-ui/core';

export const BookingQuestionsView = ({ questions }) => {
  return (
    <Stack spacing={1} sx={{ padding: (theme) => theme.spacing(2, 0) }}>
      {questions.map((item) => {
        const { id, question, answer } = item;

        return (
          <Stack key={id} spacing={0}>
            <Typography noWrap variant="subtitle2" sx={{ textWrap: 'wrap' }}>
              {question}
            </Typography>
            <Typography variant="body2" paragraph color="text.secondary">
              {answer}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};
