import React from 'react';
import { Box, Stack, Typography } from '@material-ui/core';
import { ProviderIcon, ServiceIcons } from '../PaymentIcons';

export const PaymentMethodLabel = ({
  showLogo,
  option: { key, type, title, description, icon, services },
  isMobile,
  isShowServices
}) => {
  const mobileView = (
    <Stack direction="row" spacing={3} sx={{ ml: 2, width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Typography variant="subtitle2" sx={{ color: 'text.primary', width: '100%' }}>
          {title}
        </Typography>
      </Box>
      {showLogo && <ProviderIcon isMobile={isMobile} type={type} provider={icon || key} />}
    </Stack>
  );
  const desktopView = (
    <Stack direction="column" spacing={0.3} sx={{ ml: 2, width: '100%' }}>
      <Stack direction="row" spacing={3}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            {title}
          </Typography>
          {description && (
            <Typography
              variant="body2"
              {...(isShowServices && { gutterBottom: true })}
              sx={{ color: 'text.secondary' }}
            >
              {description}
            </Typography>
          )}
        </Box>
        {showLogo && <ProviderIcon isMobile={isMobile} type={type} provider={icon || key} />}
      </Stack>
      {isShowServices && <ServiceIcons services={services} />}
    </Stack>
  );
  return isMobile ? mobileView : desktopView;
};
