import { defaultTo } from 'lodash';

export const getContainerWidth = (config, isMobile) => {
  if (isMobile) {
    return false;
  }
  switch (config) {
    case 'L':
      return 'xl';
    case 'M':
      return 'lg';
    case 'S':
      return 'md';
    default:
      return false;
  }
};

export const getComponentPaddingOrZero = (desktop, mobile, isMobile, theme) => {
  if (isMobile && !mobile) {
    return theme.spacing(0);
  }
  if (!isMobile && !desktop) {
    return theme.spacing(0);
  }
  return getComponentPadding(desktop, mobile, isMobile, theme);
};

export const getComponentPadding = (desktop, mobile, isMobile, theme) => {
  if (isMobile) {
    return !mobile
      ? theme.spacing(3, 3, 8) // top, rightLeft, bottom
      : theme.spacing(
          getValueOrDefault(mobile.top),
          getValueOrDefault(mobile.right),
          getValueOrDefault(mobile.bottom),
          getValueOrDefault(mobile.left)
        );
  }
  return !desktop
    ? theme.spacing(10)
    : theme.spacing(
        getValueOrDefault(desktop.top),
        getValueOrDefault(desktop.right),
        getValueOrDefault(desktop.bottom),
        getValueOrDefault(desktop.left)
      );
};

const getValueOrDefault = (value) => defaultTo(value, 0);
