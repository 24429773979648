import React from 'react';
import { defaultTo, isEmpty } from 'lodash';
import { Box, FormHelperText, Grid, RadioGroup, Stack } from '@material-ui/core';
import { DEFAULT_PRE_PAYMENT_BOOKING_ERROR_MESSAGE } from '../../utils/constants';
import { Loader } from '../core/Loader';
import { BookingTitle } from './components/BookingTitle';
import { PrePaymentCheckoutError } from '../core/payment/PrePaymentCheckoutError';
import { PaymentMethodList } from '../core/payment/PaymentMethodList';
import { EmptyPaymentMethods } from '../core/payment/EmptyPaymentMethod';

// checkoutError - Pre payment checkout exception. (Not post checkout exception )
export const BookingPayment = ({
  config: { general, payments, messaging },
  form,
  loading,
  loadingMessage,
  paymentMethods,
  hasError,
  handleChangePaymentOption,
  handleUpdateStripeContext
}) => {
  const titlePosition = general?.titlePosition;
  const titleSize = general?.titleSize;
  const paymentMethodsTitle = payments?.paymentMethodsTitle;
  const { setFieldValue, getFieldProps, touched, errors } = form;

  const { enable: isError, message } = hasError;

  const Title = (props) => <BookingTitle position={titlePosition} size={titleSize} {...props} />;

  return (
    <>
      {loading && <Loader message={loadingMessage} sx={{ height: '200px' }} />}
      {isEmpty(paymentMethods) ? (
        <EmptyPaymentMethods loading={loading} messaging={messaging} />
      ) : (
        <>
          <Stack spacing={2} sx={{ ...(loading && { display: 'none' }) }}>
            {isError && (
              <PrePaymentCheckoutError message={defaultTo(message, DEFAULT_PRE_PAYMENT_BOOKING_ERROR_MESSAGE)} />
            )}
            <Stack spacing={2}>
              <Title>{paymentMethodsTitle || 'Payment Methods'}</Title>
              <RadioGroup
                {...getFieldProps('payment')}
                onChange={(e) => {
                  e.preventDefault();
                  const paymentKey = e.target.value;
                  setFieldValue('payment', paymentKey);
                  handleChangePaymentOption(paymentKey);
                }}
              >
                <Box>
                  <Grid container spacing={2} direction="column">
                    <PaymentMethodList
                      config={payments}
                      options={paymentMethods}
                      form={form}
                      handleUpdateStripeContext={handleUpdateStripeContext}
                    />
                  </Grid>
                </Box>
              </RadioGroup>
              {Boolean(touched.payment && errors.payment) && (
                <FormHelperText error>{touched.payment && errors.payment}</FormHelperText>
              )}
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};
