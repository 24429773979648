import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { fDateLong, fTime } from '../../../utils/formatTime';
import { SkeletonBookingSummary } from '../../core/SkeletonBookingSummary';
import { BookingItemRootStyle } from './BookingItemRootStyle';

export const BookingSlotItem = ({ loading, slot }) => {
  if (loading) {
    return (
      <Box sx={{ p: 2 }}>
        <SkeletonBookingSummary />
      </Box>
    );
  }
  const { from } = slot;
  return (
    <BookingItemRootStyle spacing={1}>
      <Typography variant="overline" color="text.secondary">
        ARRIVAL
      </Typography>
      <Box>
        <Typography variant="h6">{from ? fDateLong(from) : '-'}</Typography>
        {from && (
          <Typography variant="body2" sx={{ fontSize: (theme) => theme.typography.pxToRem(18) }}>
            {fTime(from)}
          </Typography>
        )}
      </Box>
    </BookingItemRootStyle>
  );
};
